import React from 'react'
import '../../Styles/RepeativeStyles/KRZButton.css'
import { useNavigate } from 'react-router-dom'

const KRZButton = ({ text, nav, type, className = '' }) => {
  const navigate = useNavigate()
  // console.log('Received className:', className)

  const handleNavigate=() => {
    if(type!=='submit'){
      navigate(`/${nav}`)
    }
  }

  return (
    <button className={className} onClick={() =>handleNavigate()} type={type}>
      {text}
    </button>
  )
}

export default KRZButton
