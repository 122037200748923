import React from 'react'
import '../Styles/Careers.css'
import { useMediaQuery } from 'react-responsive'
import { Link, useNavigate } from 'react-router-dom'

const Jobopening = () => {
  const isMobile = useMediaQuery({ maxWidth: 440 })
  const navigate=useNavigate();
  const handelNavigate=() =>{
    navigate('/mailus')
  }

  return (
    <>
      <div className="no-jobs">
        <img className="jobimg" src="pics/jobvc.svg" alt="No Jobs Icon" />
        <p className="highlightjob">No job openings for now</p>
        {isMobile ? (
          <>
            <p className="jobavailability">
              Please submit your{' '}
              <span onClick={()=> handelNavigate()} className="maillink" style={{cursor:'pointer'}}>
              Mail ID 
            </span>
              {/* <a className="maillink" href="mailto:krztechnologies.com">
              Mail ID{' '}
            </a> */}
              <br />
            </p>
            <p className="job-notification">
              {' '}
              We'll notify you if any job openings are posted.
            </p>
          </>
        ) : (
          <p className="jobavailability">
            Please submit your{' '}
            <span onClick={()=> handelNavigate()} className="maillink" style={{cursor:'pointer'}}>
              Mail ID 
            </span>
            {/* <a className="maillink" href="mailto:krztechnologies.com">
              Mail ID{' '}
            </a> */}
             {' '}here. We'll notify you if any job openings are posted.
          </p>
        )}
      </div>
    </>
  )
}

export default Jobopening
