import React, { useState, useRef, useEffect } from 'react'
import KRZButton from './KRZButton'
import axios from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import Loader from '../Loader'

const KRZForm = ({ text, className = '' }) => {
  const [isVisible, setIsVisible] = useState(false)
  const krzFormRef = useRef(null)

  const mobileView = useMediaQuery({ maxWidth: 440 })
  const fileInputRef = useRef(null)
  const [toggleCheckbox, setToggleCheckBox] = useState(false)
  const [checkboxstate, setcheckboxstate] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedFiles, setSelectedFiles] = useState([])
  const [onSuccess, setOnSuccess] = useState(false)
  const [onLoad,setOnLoad]=useState(false)
  const [notSuccessMsg,setNotSuccessMsg]=useState("")
  const navigate=useNavigate();
  // const corporateEmailRegex =
  //   /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|outlook\.com|yahoo\.com)([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/


  const handleNavigate= () => {
    navigate('/career');
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('This field is required'),
    email: Yup.string()
      // .matches(
      //   corporateEmailRegex,
      //   <div>
      //     <p className="error">Please provide your business email.</p>
      //     <p className="suberror">
      //       To explore career opportunities at KRZ Technologies, please visit
      //       our careers page.
      //     </p>
      //   </div>
      // )
      .required(
        <div>
          <p className="error">Please provide your business email.</p>
          <p className="suberror">
            To explore career opportunities at KRZ Technologies, please visit
            our{' '}
            <span
              onClick={() => handleNavigate()}
              style={{ textDecoration: 'underline', color: '#B13337',cursor:'pointer' }}
            >
              careers page.
            </span>
          </p>
        </div>
      )
      .test(
        'corporate-email',
        <div>
          <p className="error">Please provide your business email.</p>
          <p className="suberror">
            To explore career opportunities at KRZ Technologies, please visit
            our{' '}
            <span
              onClick={() => handleNavigate()}
              style={{ textDecoration: 'underline', color: '#B13337',cursor:'pointer' }}
            >
              careers page.
            </span>
          </p>
        </div>,
        (value) => {
          const corporateEmailRegex =
            /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|outlook\.com|yahoo\.com)([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
          return corporateEmailRegex.test(value || '')
        }
      ),
    phoneNumber: Yup.string()
      .test('is-numeric',<p>Phone number must consist of only numbers.</p>, (value) => {
        return value ? /^[0-9]+$/.test(value) : true; // Allow empty input
      }),
    requirement: Yup.string().required('This field is required'),
    // acceptTerms: Yup.boolean()
    //   .oneOf([true], 'You must accept the terms and conditions')
    //   .required('Terms must be accepted'),
    // Optionally you can add phone number validation
  })

  // Formik setup
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phoneNumber: '',
      requirement: '',
     
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      values.preventDefault()
      console.log('Form Data')
      // Handle form submission
      if (!toggleCheckbox) {
        setcheckboxstate(true)
        return
      } else {
        console.log('gfjghjkj')
      }
    },
  })

  const handleFileClick = () => {
    // Trigger the file input click
    fileInputRef.current.click()
  }

  const handleFileChange = (event) => {
    setErrorMessage('') // Reset error message
    const files = event.target.files
    const maxFiles = 3
    const maxFileSizeMB = 5
    let validFiles = []

    // Check if more than 3 files are selected
    if (files.length > maxFiles || selectedFiles.length > maxFiles) {
      setErrorMessage(
        `Files you’ve choosed are too large! Please upload files up to 5 MB.`
      )
      return
    }

    // Check if any file exceeds 5 MB
    for (let i = 0; i < files.length; i++) {
      const fileSizeMB = files[i].size / (1024 * 1024) // Convert bytes to MB
      if (fileSizeMB > maxFileSizeMB) {
        setErrorMessage(`${files[i].name} exceeds the 5 MB limit.`)
        return
      }
      validFiles.push(files[i])
    }

    setSelectedFiles([...validFiles, ...selectedFiles])
  }

  const handleFilter = (fileName) => {
    const fiteredFile = selectedFiles.filter((file) => file !== fileName)
    setSelectedFiles(fiteredFile)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true)
            observer.unobserve(entry.target) // Stop observing once visible
          }
        })
      },
      { threshold: 0.1 }
    )

    if (krzFormRef.current) {
      observer.observe(krzFormRef.current)
    }

    return () => {
      if (krzFormRef.current) observer.unobserve(krzFormRef.current)
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Mark all fields as touched to trigger validation errors
    formik.setTouched({
      name: true,
      email: true,
      phoneNumber: true,
      requirement: true,
    });
  
    // Validate form and wait for validation result
    await formik?.validateForm();
  
    // Check if there are errors in formik.errors or if checkbox is unchecked
    if (Object.keys(formik?.errors).length > 0 || !toggleCheckbox) {
      if (!toggleCheckbox) {
        setcheckboxstate(true); // Show error if checkbox is not checked
      }
      // console.log("Form has errors or checkbox is not checked", formik.errors);
      return;
    }
  
    // If form is valid and checkbox is checked, proceed with submission
    try {
      setOnLoad(true);
      const contact = await axios.post(`${process.env.REACT_APP_API_URL}/contactus`, {
        name: formik.values.name,
        email: formik.values.email,
        phoneNumber: formik.values.phoneNumber,
        requirement: formik.values.requirement,
        files: selectedFiles,
        acceptTerms: toggleCheckbox,
      });
  
       // Resets Formik fields
      // Reset checkbox error state
    } catch (err) {
      console.log(err);
      setNotSuccessMsg("Sorry, Try again Later");
    } finally {
      setOnLoad(false);
      setOnSuccess(true);
      formik.resetForm();
      setSelectedFiles([]); // Clears selected files
      setToggleCheckBox(false); // Reset checkbox
      setErrorMessage(''); // Clear error message
      setcheckboxstate(false); 
    }
  };



  useEffect(() => {
    const interval = setTimeout(() => {setOnSuccess(false)}, 5000)
    return () => clearTimeout(interval)
  }, [onSuccess])

  useEffect(() =>{
    const interval = setTimeout(() => {setNotSuccessMsg("")}, 5000)
    return () => clearTimeout(interval)
  },[notSuccessMsg])

  useEffect(()=>{
    const interval=setTimeout(()=>setOnLoad(false),1500)
    return () => clearTimeout(interval)
  },[onLoad])

  return (
    // <form onSubmit={formik.handleSubmit} style={{ padding: '0vw 0vw 3vw' }}>
    <div ref={krzFormRef} className={`cform ${isVisible ? 'visible' : ''}`}>
          <form onSubmit={(e)=>handleSubmit(e)}>
        <div className="requiredFiledsContainer">
          <span className="compulsaryLabel">*</span>
          <span className="requiredFileds">Required field</span>
        </div>

        {/* Name Field */}
        <div className="contactusFormField">
          <label className="contactusFormLabel">
            Name<sup className="compulsaryLabel">*</sup>
          </label>
          <br />
          <input
            type="text"
            className={`formInput ${
              formik.touched.name && formik.errors.name ? 'inputError' : ''
            }`}
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="error">{formik.errors.name}</div>
          ) : null}
        </div>

        {/* Corporate Email Field */}
        <div className="contactusFormField" style={{ marginBottom: '1.0vw' }}>
          <label className="contactusFormLabel">
            Corporate email<sup className="compulsaryLabel">*</sup>
          </label>
          <br />
          <input
            type="text"
            className={`formInput ${
              formik.touched.email && formik.errors.email ? 'inputError' : ''
            }`}
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="error">{formik.errors.email}</div>
          ) : null}
        </div>

        {/* Phone Number Field */}
        <div className="contactusFormField">
          <label className="contactusFormLabel">Phone Number</label>
          <br />
          <input
            type="text"
            className="formInput"
            name="phoneNumber"
            onChange={formik.handleChange}
            value={formik.values.phoneNumber}
          />
           {formik.errors.phoneNumber ? (
            <div className="error">{formik.errors.phoneNumber}</div>
          ) : null}
        </div>

        {/* Requirement Field */}
        <div className="contactusFormField">
          <label className="contactusFormLabel">
            Your Requirement<sup className="compulsaryLabel">*</sup>
          </label>
          <br />
          <textarea
            type="text"
            className={`formInput formTextArea ${
              formik.touched.requirement && formik.errors.requirement
                ? 'inputError'
                : ''
            }`}
            name="requirement"
            style={{ border: '0.1389vw solid #DEDEDE' }}
            placeholder="Please provide your project requirement"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.requirement}
          ></textarea>
          {formik.touched.requirement && formik.errors.requirement ? (
            <div className="error">{formik.errors.requirement}</div>
          ) : null}
        </div>

        {/* File Upload */}
        <div className="formAttachContainer">
          <span className="formAttachClickContainer" onClick={handleFileClick}>
            <img
              src="pics/attachpin.svg"
              alt="attach pin"
              className="attachpin"
            />{' '}
            <span className="attachpinName">Attach files</span>{' '}
          </span>
          <input
            type="file"
            ref={fileInputRef}
            multiple
            accept=".docx,.pdf,.ppt,.pptx,.xlsx,.csv"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          {selectedFiles?.length <= 0 && (
            <span className="attachInfo">
              No more than 3 files may be attached up to 5 MB each. Formats:
              docx, pdf, ppt, pptx, xlsx, csv.
            </span>
          )}
        </div>
        {errorMessage && <div className="error">{errorMessage}</div>}
        {/* Display selected files */}
        {
          selectedFiles.length > 0 &&
            // <ul style={{listStyleType:"none",padding:0,margin:"0.2778vw 0 0 0"}}>
            selectedFiles.map((file, index) => (
              <li key={index} className="fileuploadcancelContainer">
                <img
                  src="/pics/formuploadcancel.svg"
                  alt="close"
                  style={{
                    width: mobileView ? '1.6944vw':'0.7694vw',
                    margin:mobileView?'0 3.0417vw 0 0' :'0.15vw 0.7639vw 0 0.7639vw',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleFilter(file)}
                />
                {file.name}
              </li>
            ))
          // </ul>
        }

        {/* Checkbox */}
        <div className="checkboxContainer">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <img
              src={`${
                toggleCheckbox === true
                  ? 'pics/checkedbox.svg'
                  : 'pics/uncheckedbox.svg'
              }`}
              alt="checkbox"
              onClick={() => {
                setToggleCheckBox(!toggleCheckbox)
                toggleCheckbox && setcheckboxstate(false)
              }}
              className="checkbox"
              // values={formik.values.acceptTerms}
            />
            <p className="termsAndConditionPara">
              I have read and accepted the Terms and condition and Privacy
              policy{' '}
            </p>
          </div>
          {checkboxstate && !toggleCheckbox ? (
            <div className='acceptTerms'
              style={{ color: "#b13337", fontSize: '0.8vw', marginLeft: '3.5vw' }}
            >
              You must accept the terms and conditions
            </div>
          ) : null}
        </div>

        {/* Submit Button */}
        {/* {mobileView ? (
        <KRZButton text="Send Request" type="submit" />
      ) : (
        <div
          style={{ textAlign: 'right', marginRight: '0.2vw', marginTop: '2vw' }}
        >
          <KRZButton className={className} text="Submit" type="submit">
            Submitte
          </KRZButton>
        </div>
      )} */}
      {notSuccessMsg.length===0 ?onSuccess && <div className="statusMsg1">
          <img
            src="pics/circleTick.svg"
            className="tickImg1"
            alt="form-status-icon"
          />
          <p className="formMsg1">Request sent Successfully</p>
        </div>: <div className='statusMsg'><p className='formMsg' style={{color:"red"}}>{notSuccessMsg}</p></div>}
        {!onLoad ?<KRZButton className="contactusbtn" text={text} type="submit" />: <div style={{textAlign:"right",marginTop: "1.5vw"}}><Loader/></div>}
      </form>
    </div>
  )
}

export default KRZForm
