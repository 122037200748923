import React, { useEffect } from "react";
import MailUsForm from "./MailUsForm";
import "../Styles/MailUs.css";
import { useMediaQuery } from "react-responsive";
// import {useAppContext} from "../Appcontext";
// Add appropriate styling

const MailUs = () => {
  const mobileView = useMediaQuery({maxWidth : 440})
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '100px', backgroundColor: '#f0f0f0' }}>
      <div style={{ backgroundColor: 'lightblue', width: '30%' }}>Item 1</div>
      <div style={{ backgroundColor: 'lightcoral', width: '30%' }}>Item 2</div>
      <div style={{ backgroundColor: 'lightgreen', width: '30%' }}>Item 3</div>
    </div> */}
      <div className="MailUsContainer" >
        <div className="MailUsContentContainer">
          <p className="MailUsContentTitle">We’ll notify you </p>
          <p className="MailUsContentST" >
            if any job openings are posted in our website.
          </p>
          {/* Use the MailUsForm component here */}
        </div>
          <MailUsForm text="Submit"  className="mailtouskrz" />
      </div>
      {/* <div className="MailUsContainer" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
  <div className="MailUsContentContainer">
    <p className="MailUsContentTitle">We’ll notify you </p>
    <p className="MailUsContentST">if any job openings are posted on our website.</p>
    <div style={{ width: '100%', height: '50px', backgroundColor: 'lightgray' }}>Static Content</div>
  </div>
</div> */}

    </>
  );
};

export default MailUs;
