import React from "react";
import "../../Styles/Subpages/GetInTouch.css";
import {useNavigate } from "react-router-dom";

const GetInTouch = () => {
  const navigate=useNavigate();

  const handleNavigate=() =>{
    navigate("/contactus")
  }

  return (
    <div className="contactContainer">
      <div className="contactContent">
        <p className="contactMainContent">Your Next Step Starts Here</p>
        <p className="contactSupportContent">
          Connect with us to begin your journey toward achieving your business
          goals.
        </p>
      </div>
      <div className="getInTouchContainer" onClick={() => handleNavigate()}>
        <span className="getInTouchContent">Get In Touch</span>
      </div>
    </div>
  );
};

export default GetInTouch;
