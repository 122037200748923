import React, { useState,useEffect,useRef } from 'react'
import '../Styles/OurValues.css'
import { useAppContext } from '../Appcontext'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

const OurValues = () => {
  const mobileView = useMediaQuery({ maxWidth: 440 })
  const navigate = useNavigate()
  const { setCompValues } = useAppContext()
  const [hoveredVal, setHoveredVal] = useState(null)
  const [isVisible, setIsVisible] = useState(false);
  const ourValuesRef = useRef(null);

  const companyValues = [
    {
      name: 'Long-Term Support',
      img: 'gifs/longterm_support1.gif',
      hovered: 'gifs/longterm_hovered.gif',
      order: mobileView? 5: 1,
    },
    {
      name: 'Work Quality',
      img: 'gifs/work_quality.gif',
      hovered: 'gifs/work_quality_hovered.gif',
      order: mobileView? 1:2
    },
    {
      name: !mobileView? 'Expertise and Professionalism': 'Professionalism' ,
      img: 'gifs/expertise_professionalism.gif',
      hovered: 'gifs/expertise_hovered.gif',
      order: 3
    },
    {
      name: 'Transparent Communication',
      img: 'gifs/transparent_communication.gif',
      hovered: 'gifs/transparent_hovered.gif',
      order: 4,
    },
    {
     name: mobileView?'Transparent Service':'Dependable and Transparent Service',
      img: 'gifs/dependable_transport_service.gif',
      hovered: 'gifs/dependable_hovered.gif',
      order:  mobileView? 2:5
    },
  ]
// Sort values based on the order property before rendering
const sortedValues = [...companyValues].sort((a, b) => a.order - b.order);
  const handleNavigate = (val) => {
    if(val==='Transparent Service'){
      val='Dependable and Transparent Service'
    }else if (val==='Professionalism' ){
val='Expertise and Professionalism'
    }
    setCompValues(val)
    navigate('values')
  }



  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target); // Stop observing once visible
          }
        });
      },
      { threshold: 0.1 }
    );

    if (ourValuesRef.current) {
      observer.observe(ourValuesRef.current);
    }

    return () => {
      if (ourValuesRef.current) observer.unobserve(ourValuesRef.current);
    };
  }, []);


  return (
    <div ref={ourValuesRef} className={`OurValuesContainer ${isVisible ? "visible" : ""}`} data-testid="our-values">
      {!mobileView && (
        <img
          src="pics/values-background.svg"
          alt="values design"
          className="valuesbackground"
        />
      )}
      <div className="overallContent">
        <p className="ourvaluesTitle">Our Values</p>
        <p className="ourValuesContent">
          As KRZ Technologies, we are committed to deliver excellence in every
          service we provide.
        </p>
      </div>
      <div className="compValuesContainer">
        {companyValues.map((val, index) => (
          <div
            key={index}
            className="valueContainer"
            style={{ order: val.order }}//1
            // onClick={() => handleNavigate(val.name)}
            onClick={() => handleNavigate(companyValues[index].name)}
          >
            <img
            //  src={hoveredVal === index ? val.hovered : val.img}
            //  alt={val.name}
            //  className="gifImage"
            //  onMouseEnter={() => setHoveredVal(index)}
            //  onMouseLeave={() => setHoveredVal(null)}
              src={
                hoveredVal === index
                  ? companyValues[index].hovered
                  : companyValues[index].img
              }
              alt={val}
              className="gifImage"
              onMouseEnter={() => setHoveredVal(index)}
              onMouseLeave={() => setHoveredVal(null)}
            />
              {/* <p className="valueName">{val.name}</p> */}
            <p className={`valueName ${mobileView&& `valueName${index}`}`}>{companyValues[index].name}</p>
          </div>
        ))}
      </div>

      {/* <img src="pics/valuesblock.svg" alt="valuesblock" className='valuesblock'/> */}
    </div>
  )
}

export default OurValues
