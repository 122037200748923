import "./App.css";
import { Routes, Route,useLocation } from "react-router-dom";
import Aboutus from "./Components/Aboutus";
import Contactus from "./Components/Contactus";
import Home from "./Components/Home";
import TopNavbar from "./Components/TopNavbar";
import Footer from "./Components/Footer";
import Values from "./Components/Subpages/Values";
import WhyKRZ from "./Components/Subpages/WhyKRZ";
import DynamicTermsAndPrivacy from "./Components/FooterPages/DynamicTermsAndPrivacy";
// import Cookies from "./Components/FooterPages/Cookies";
// import { useAppContext } from "./Appcontext";
import Occupation from "./Components/Occupation";
import MailUs from "./Components/MailUs";
import NotFound from "./Components/NotFound";
function App() {
  // const { open, setOpen } = useAppContext();
  const location = useLocation();

  // Array of paths that should show the header and footer
  const pathsWithHeaderFooter = [
    "/", 
    "/aboutus", 
    "/contactus", 
    "/values", 
    "/whykrz", 
    "/career", 
    "/mailus", 
    "/policy", 
    "/terms", 
    "/cookie"
  ];

  // Determine if header and footer should be shown
  const shouldShowHeaderFooter = pathsWithHeaderFooter.includes(location.pathname);

  return (
    <div className="App">
    
      {shouldShowHeaderFooter && <TopNavbar />}
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path='/services' element={<Services/>} /> */}
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/values" element={<Values />} />
          <Route path="/whykrz" element={<WhyKRZ />} />
          <Route path="/policy" element={<DynamicTermsAndPrivacy />} />
          <Route path="/terms" element={<DynamicTermsAndPrivacy />} />
          <Route path="/cookie" element={<DynamicTermsAndPrivacy />} />
          <Route path="/career" element={<Occupation />} />
          <Route path="/mailus" element={<MailUs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

        {shouldShowHeaderFooter && <Footer />}
    </div>
  );
}

export default App;
