import React, { useState, useEffect, useRef } from 'react'
import '../Styles/WhyKRZ.css'
import KRZButton from './RepeativeComponent/KRZButton'
import { useMediaQuery } from 'react-responsive'

const WhyKRZ = () => {
  const [isVisible, setIsVisible] = useState(false)
  const whyKRZRef = useRef(null)
  const mobileView = useMediaQuery({ maxWidth: 440 })

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true)
            observer.unobserve(entry.target) // Stop observing once visible
          }
        })
      },
      { threshold: 0.1 }
    )

    if (whyKRZRef.current) {
      observer.observe(whyKRZRef.current)
    }

    return () => {
      if (whyKRZRef.current) observer.unobserve(whyKRZRef.current)
    }
  }, [])

  return (
    <div
      ref={whyKRZRef}
      className={`WhyKRZContainer ${isVisible ? 'visible' : ''}`}
      data-testid="why-krz"
    >
      {mobileView && (
        <img src="pics/why.svg" alt="whybanner" className="whybannero" />
      )}
      <img src="pics/whyKRZ.svg" alt="desktop" className="whyKRZImage" />
      <div
        ref={whyKRZRef}
        className={`whyKRZContentContainer ${isVisible ? 'visible' : ''}`}
      >
        <p className="whyKRZTitle">Why KRZ Technologies?</p>
        <p className="whyKRZContent">
          “KRZ Technologies brings fresh, innovative solutions with youthful
          energy and creativity. Our commitment to continuous learning ensures
          we stay ahead of trends, delivering cutting-edge, tailored services
          that meet your unique needs and achieve outstanding results”
        </p>
        <KRZButton
          text={mobileView ? 'more' : 'Know more'}
          className={`morebtn ${mobileView && "krzMoreBTN"}`}
          nav="whykrz"
          type="button"
        />
      </div>
    </div>
  )
}

export default WhyKRZ
