import React from 'react'
import '../Styles/Loader.css'
import { useMediaQuery } from 'react-responsive'

const Loader = () => {
  const mobileView = useMediaQuery({ maxWidth: 440 })
  return (
    <div style={{marginRight:mobileView? "10vw": "2.5vw",marginTop:"3vw"}}>
      <svg class="spin" width="3.2vw" height="3.2vw" viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          r="35"
          stroke="#d3d3d3"
          stroke-width="10"
          fill="none"
        />
        <circle
          cx="50"
          cy="50"
          r="35"
          stroke="rgba(251, 176, 59, 1)"
          stroke-width="10"
          fill="none"
          stroke-linecap="round"
          stroke-dasharray="65 195"
        />
      </svg>
    </div>
  )
}

export default Loader
