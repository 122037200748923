import React, { useEffect, useRef } from "react";
import "../Styles/Aboutus.css";
// import "../App.css"
import { useMediaQuery } from "react-responsive";
import { useAppContext } from "../Appcontext";
import GetInTouch from "./Subpages/GetInTouch";
const Aboutus = () => {
  const mobileView = useMediaQuery({ maxWidth: 440 });
  const bannerSrc = mobileView ? "pics/eclipse.svg" : "pics/aboutusBanner.png";

  const aboutUsPoints = [
    {
      name: "Sustainability",
      point:
        "KRZ technologies support clients in advancing their goals on the environment, social and governance by connecting sustainability with their transformations; operate our business with a strong commitment to the environment, ethics, and human rights; and work to create value in communities around the world.",
    },
    {
      name: "Inclusivity and Diversity",
      point:
        "This will be achieved by hiring and developing people with diverse backgrounds, different perspectives, and differences in lived experiences. These differences guarantee that we have and attract cognitive diversity to deliver a variety of perspectives, observations, and insights essential for driving the innovation needed to reinvent",
    },
    {
      name: "Making a Positive Impact",
      point:
        "KRZ Technologies dedicated in making a positive impact globally and being responsible leaders for our employees, clients, and the communities where we live and work.",
    },
    {
      name: "Our Ethics",
      point:
        "Our team is deeply committed to do the right thing. Together, we've demonstrated our ability to succeed by delivering value to our clients and shareholders while creating opportunities for our employees. This dedication to high ethical standards and making a positive impact is what sets KRZ Technologies apart.",
    },
  ];

  // Refs for each aboutPointContentContainer
  const containerRefs = useRef([]);

    useEffect(() => {
    // Scroll to the top of the page when this component is mounted
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            observer.unobserve(entry.target); // Stop observing once animated
          } else {
            entry.target.classList.remove("visible"); // Optional: to reset animation when out of view
          }
        });
      },
      { threshold: 0.1 }
    );

    containerRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      containerRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <div className="aboutusContainer">
      <div className="aboutusBannerContainer">
        <img src={bannerSrc} alt="Aboutus Banner" className="aboutusBannerBg" />
        <div className="aboutusBannerTopContentContainer">
          <p className="aboutusBannerTopContentIntro">About us</p>
          <p className="aboutusBannerTopMainContent">
            Transforming the legal experience for everyone is our mission
          </p>
          <p className="aboutusBannerTopSupportContent">
            We aim to inspire growth and innovation through forward-thinking
            strategies, empowering businesses to adapt and thrive in the digital
            age. With a focus on integrity, collaboration, and excellence, we
            are committed in delivering impactful results for the future.
          </p>
        </div>
        {mobileView && (
          <img
            src="pics/vision.svg"
            alt="Aboutus Vision"
            className="aboutusBannerVision"
          />
        )}
        <p className="aboutusBannerBottomContent">
          "To become a leader in{" "}
          <span className="aboutusBannerBottomContentHL">
            technological innovation
          </span>{" "}
          by driving transformative solutions that shape a smarter and more
          connected world."
        </p>
      </div>
      {mobileView ? (
        <>
          {" "}
          <div className="aboutusSecondContainer">
            {aboutUsPoints.map((aboutPoint, index) => (
              <div
                className={`aboutPointContainer ${
                  index >= 0
                    ? "aboutPointContainerDirection0"
                    : "aboutPointContainerDirection1"
                }`}
              >
                <div
                  className={`${index >= 0 ? "aboutPointSideDesign" : ""}`}
                ></div>
                <div className="aboutPointContentContainer">
                  <p className="aboutPointHeading">{aboutPoint.name}</p>
                  <p className="aboutPointPara">{aboutPoint.point}</p>
                </div>
              </div>
            ))}
          </div>
          <GetInTouch />
        </>
      ) : (
        <>
          {" "}
          <div className="aboutusSecondContainer">
            {aboutUsPoints.map((aboutPoint, index) => (
              <div
                key={index}
                ref={(el) => (containerRefs.current[index] = el)}
                className={`aboutPointContainer ${
                  index % 2 == 0
                    ? "aboutPointContainerDirection0"
                    : "aboutPointContainerDirection1"
                }`}
              >
                <div
                  // ref={(el) => (containerRefs.current[index] = el)} // Assign refs to each container
                  className={`aboutPointContentContainer ${
                    index % 2 === 0
                      ? "aboutPointContentContainer0"
                      : "aboutPointContentContainer1"
                  }`}
                >
                  <p className="aboutPointHeading">{aboutPoint.name}</p>
                  <p className="aboutPointPara">{aboutPoint.point}</p>
                </div>
                {index !== 0 && (
                  <div
                    //  ref={(el) => (containerRefs.current[index] = el)} // Assign refs to each container
                    className={`aboutPointSideDesign ${
                      index % 2 === 0
                        ? "aboutPointSideDesignDirection0"
                        : "aboutPointSideDesignDirection1"
                    }`}
                  ></div>
                )}
              </div>
            ))}
          </div>
          <GetInTouch />
        </>
      )}
    </div>
  );
};

export default Aboutus;
