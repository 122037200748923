import React, { useState } from "react";
import "../Styles/Careers.css";
import CustomDropdown from "./CustomDropDown";
import { useMediaQuery } from "react-responsive";

// const options = [
//   { value: '1', label: 'Option 1' },
//   { value: '2', label: 'Option 2' },
//   { value: '3', label: 'Option 3' },
// ];

const Jobvacancies = () => {
  // const [inputValue, setInputValue] = useState('');
  const isMobile = useMediaQuery({ maxWidth: 440 });
  //   const handleInputChange = (e) => {
  //     setInputValue(e.target.value);
  // };

  return (
    <>
      <div className="job-vacancies-section">
        <p>Job Vacancies</p>
      </div>
      <div className="search-container">
        {isMobile ? (
          <div className="sdiv">
            {" "}
            <img
              src="pics/searchicon.svg"
              alt="jobsearch"
              className="search-icon"
            />
            <input
              type="text"
              // value={inputValue}
              // onChange={handleInputChange}
              placeholder="Type your job role"
              className="job-input"
            />
          </div>
        ) : (
          <>
            <img
              src="pics/searchicon.svg"
              alt="jobsearch"
              className="search-icon"
            />
            <input
              type="text"
              // value={inputValue}
              // onChange={handleInputChange}
              placeholder="Type your job role, type, skill"
              className="job-input"
            />
          </>
        )}

        <CustomDropdown />
        {/* <select className="job-type">
           <option value="" disabled selected>Job Type</option>
           <option>Part time</option>
           <option>Remote</option>
           <option>Full time</option>
           <option>Internship</option>
           </select> */}
        {/* <select  options={options}
            placeholder="Select an option"/> */}
        {isMobile ? (
          ""
        ) : (
          <button type="button" className="search-btn">
            Search
          </button>
        )}
      </div>
      <div className="job-search">
        {/* <input
            type="text"
            placeholder="Type your job role, type, skill"
            className="job-input"
          /> */}
        {/* <select className="job-type">
            <option>Front-End Developer</option>
            <option>UI & UX Design</option>
          </select> */}
        {/* <button type="button" className="search-btn">Search</button> */}
      </div>
    </>
  );
};

export default Jobvacancies;
