import React, { useState, useRef, useEffect } from "react";
import "../Styles/CustomDropDown.css";
import { useMediaQuery } from "react-responsive";

const CustomDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 440 });
  // const [inputValue, setInputValue] = useState(''); // State for input value

  const options = [
    { value: "1", label: "Part Time" },
    { value: "2", label: "Remote" },
    { value: "3", label: "Full time" },
    { value: "4", label: "Internship" },
  ];

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  // Function to close the dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      setSelectedOption(null); // Reset selected option
      // setInputValue(''); // Clear input text
    }
  };

  useEffect(() => {
    // Bind the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener on unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
        {isMobile ? (
          <img
            src="pics/job-box.svg"
            alt="job-type-image"
            className="job-box"
          />
        ) : (
          ""
        )}

        {selectedOption ? (
          selectedOption.label
        ) : (
          <p className="job-type-lbl">Job Type</p>
        )}
        {/* <span className="arrow">{isOpen ? '^' : 'v'}</span> */}
        <img
          src={isOpen ? "pics/arrow-up.svg" : "pics/arrow-down.svg"}
          alt="job-options"
          className="arrow"
        />
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {options.map((option) => (
            <div key={option.value} onClick={() => handleOptionClick(option)}>
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
