// import "../Styles/MailUs.css";

import React, { useState, useRef, useEffect } from 'react'
// import KRZButton from "./KRZButton";
// import '../Styles/RepeativeStyles/KRZButton.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import KRZButton from './RepeativeComponent/KRZButton'
// import MailUsButton from "./MailUsButton";
// import { Link } from "react-router-dom";
import axios from 'axios'
import { useMediaQuery } from 'react-responsive'
import Loader from './Loader';

const MailUsForm = ({ text, className = '' }) => {
  const fileInputRef = useRef(null)
  const mobileView = useMediaQuery({ maxWidth: 440 })
  // const [toggleCheckbox, setToggleCheckBox] = useState(false);
  const [onSuccess, setOnSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedFiles, setSelectedFiles] = useState([])
  const [isResumeAttach,setIsResumeAttach]=useState(false);
  const [onLoad,setOnLoad]=useState(false);
  const [notSuccessMsg,setNotSuccessMsg]=useState("");

  const corporateEmailRegex =
    // /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|outlook\.com|yahoo\.com)([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    ['@']
  const validationSchema = Yup.object({
    name: Yup.string().required('This field is required'),
    jobTitle: Yup.string().required('This field is required'),
    email: Yup.string().required(
      <div>
        <p className="error">Please provide your email.</p>
        {/* <p className="suberror">
          To explore career opportunities at KRZ Technologies, please visit
          our{" "}
          <Link
            to={"aboutus"}
            style={{ textDecoration: "underline", color: "#B13337" }}
          >
            careers page.
          </Link>
        </p> */}
      </div>
    ).test(
      <div>
        <p className="error">Please enter valid email.</p>
      </div>,
      (value) => {
          const corporateEmailRegex =
            /^[a-zA-Z0-9._%+-]+@(gmail\.com|outlook\.com|yahoo\.com)$/
          return corporateEmailRegex.test(value || '')
        }
    )
     
    // requirement: Yup.string().required("This field is required"),
  })

  // Formik setup
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      jobTitle: '',
    },
    validationSchema: validationSchema,
  })

  // const handleFileClick = () => {
  //   // Trigger the file input click
  //   fileInputRef.current.click();
  // };

  // const handleFileChange = (event) => {
  //   setErrorMessage(""); // Reset error message
  //   const files = event.target.files;
  //   const maxFiles = 3;
  //   const maxFileSizeMB = 20;
  //   let validFiles = [];

  //     return;

  // };
  // style={{ display: 'flex', flexDirection: 'column', flex: 1 }}

  const handleFileClick = () => {
    // Trigger the file input click
    fileInputRef.current.click()
  }

  const handleFileChange = (event) => {
    setErrorMessage('') // Reset error message

    const files = event.target.files
    const maxFiles = 1
    const maxFileSizeMB = 5
    let validFiles = []

    // Check if more than 3 files are selected
    if (files.length > maxFiles || selectedFiles.length > maxFiles) {
      setErrorMessage(
        `File you’ve choosed are too large! Please upload files up to 5 MB.`
      )
      return
    }

    // Check if any file exceeds 5 MB
    for (let i = 0; i < files.length; i++) {
      const fileSizeMB = files[i].size / (1024 * 1024) // Convert bytes to MB
      if (fileSizeMB > maxFileSizeMB) {
        setErrorMessage(`${files[i].name} exceeds the 5 MB limit.`)
        return
      }
      validFiles.push(files[i])
    }
    setIsResumeAttach(false)
    setSelectedFiles([...validFiles])
    
  }

  const handleFilter = () => {
    // const fiteredFile = selectedFiles.filter((file) => file !== fileName)
    setSelectedFiles([])
  }

  const handleSubmit = async (e) => {
   
    e.preventDefault()
    formik.setTouched({
      name: true,
      email: true,
      jobTitle:true
    });

    await formik?.validateForm();

    if (Object.keys(formik?.errors).length > 0 || selectedFiles.length === 0) {
      if (selectedFiles.length === 0) {
        setIsResumeAttach(true);
        setErrorMessage("Kindly upload your resume.") // Show error if checkbox is not checked
      }
      console.log("Form has errors or checkbox is not checked", formik.errors);
      return;
    }
    // console.log('Form Data', formik.values)
   
    setOnLoad(true)
    try {
      
      const mailUs = await axios.post(`${process.env.REACT_APP_API_URL}/jobprofile`, {
        name: formik.values.name,
        email: formik.values.email,
        jobTitle: formik.values.jobTitle,
        files: selectedFiles,
      })
      // Clear error message

    } catch (err) {
      console.log(err);
      setNotSuccessMsg("Sorry, Try again Later");
    }finally{
      setOnLoad(false);
      setOnSuccess(true)
      formik.resetForm(); // Resets Formik fields
      setSelectedFiles([]); // Clears selected files
      setErrorMessage('');
    }
  }

  useEffect(() => {
    const interval = setTimeout(() => {setOnSuccess(false)}, 5000)
    return () => clearTimeout(interval)
  }, [onSuccess])

  useEffect(() =>{
    const interval = setTimeout(() => {setNotSuccessMsg("")}, 5000)
    return () => clearTimeout(interval)
  },[notSuccessMsg])



  useEffect(() => {
    const interval=setTimeout(() =>
      setOnLoad(false),1500)
    return ()=> clearTimeout(interval);
  },[onLoad])

  useEffect(() =>{
    if(selectedFiles.length!==0){
      setIsResumeAttach(false);
    }
  },[selectedFiles,errorMessage])

  return (
    // <form className="mailForm" onSubmit={formik.handleSubmit} >
   
    <form className="mailForm" onSubmit={handleSubmit}>
      <div className="requiredFiledsContainer">
        <span className="compulsaryLabel">*</span>
        <span className="requiredFileds">Required field</span>
      </div>

      {/* Name Field */}
      <div className="MailUsFormField">
        <label className="MailUsFormLabel">
          Name<sup className="compulsaryLabel">*</sup>
        </label>
        <br />
        <input
          type="text"
          className={`formInput ${
            formik.touched.name && formik.errors.name ? 'inputError' : ''
          }`}
          name="name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
        />
        {formik.touched.name && formik.errors.name ? (
          <div className="error">{formik.errors.name}</div>
        ) : null}
      </div>

      {/*  Email Field */}
      <div className="MailUsFormField">
        <label className="MailUsFormLabel">
          Mail id<sup className="compulsaryLabel">*</sup>
        </label>
        <br />
        <input
          type="text"
          className={`formInput ${
            formik.touched.email && formik.errors.email ? 'inputError' : ''
          }`}
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="error">{formik.errors.email}</div>
        ) : null}
      </div>

      {/* Job Title Field */}
      <div className="MailUsFormField">
        <label className="MailUsFormLabel">
          Job title<sup className="compulsaryLabel">*</sup>
        </label>
        <br />
        <input
          type="text"
          className={`formInput ${
            formik.touched.jobTitle && formik.errors.jobTitle
              ? 'inputError'
              : ''
          }`}
          name="jobTitle"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.jobTitle}
        />
        {formik.touched.jobTitle && formik.errors.jobTitle ? (
          <div className="error">{formik.errors.jobTitle}</div>
        ) : null}
      </div>

      {/* File Upload */}
      <div className="formAttachContainer">
        <span className="formAttachClickContainer" onClick={handleFileClick}>
          <img
            src="pics/attachpin.svg"
            alt="attach pin"
            className="attachpin"
          />{''}
          <span className="attachpinName">Resume</span>{' '}
        </span>
        <input
          type="file"
          ref={fileInputRef}
          multiple
          accept=".pdf"
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        {isResumeAttach || selectedFiles?.length <= 0 && (
          <span className="attachInfo">
            Upload PDF file, Be sure to include an updated resume
          </span>
        )}
      </div>
      {errorMessage && <div className="error">{errorMessage}</div>}
      {/* Display selected files */}
      {
        selectedFiles.length > 0 &&
          // <ul style={{listStyleType:"none",padding:0,margin:"0.2778vw 0 0 0"}}>
          selectedFiles.map((file, index) => (
            <li key={index} className="fileuploadcancelContainer">
              <img
                src="/pics/formuploadcancel.svg"
                alt="close"
                style={{
                  width: mobileView ? '1.6944vw':'0.7694vw',
                  margin:mobileView?'0 3.0417vw 0 0' :'0.15vw 0.7639vw 0 0.7639vw',
                  cursor: 'pointer',
                }}
                onClick={() => handleFilter()}
              />
              {file.name}
            </li>
          ))
        // </ul>
      }

{notSuccessMsg.length===0 ? onSuccess && <div className="statusMsg">
          <img
            src="pics/circleTick.svg"
            className="tickImg"
            alt="form-status-icon"
          />
          <p className="formMsg">Request sent Successfully</p>
        </div>:  <div className='statusMsg'><p className='formMsg' style={{color:"red"}}>{notSuccessMsg}</p></div>}

      {/* Submit Button */}
      <div
        style={{
          textAlign: 'right',
          marginRight: '0.2vw',
          marginTop: '3.3333vw',
        }}
      >
         {!onLoad ?<KRZButton className={"mailtouskrz"} text={text} type="submit"/>: <Loader/>}
      </div>
    </form>
  )
}

export default MailUsForm
