import React, { useEffect } from "react";
import "../../Styles/Subpages/Values.css";
import { useAppContext } from "../../Appcontext";
import GetInTouch from "./GetInTouch";
import { useNavigate } from "react-router-dom";

const Values = () => {
  const { compValues, setCurrentNav } = useAppContext();
  const navigate = useNavigate();

  const maincontent = {
    "Dependable and Transparent Service": {
      value: "Dependable and Transparent Service",
      support:
        "We KRZ Technologies Emphasizes the commitment to provide consistent, trustworthy, and clear communication in all interactions.",
    },
    "Long-Term Support": {
      value: "Long-Term Support",
      support:
        "As KRZ Technologies, we believe in fostering lasting relationships with our clients. Our long-term support services are designed to ensure that your technology solutions continue to evolve and meet your needs as your business grows.",
    },
    "Expertise and Professionalism": {
      value: "Expertise and Professionalism",
      support:
        "As KRZ Technologies, our team of seasoned professionals brings deep expertise and dedication to every project. Being highly skilled in their fields, they maintain the highest standards of professionalism throughout the project lifecycle.",
    },
    "Work Quality": {
      value: "Work Quality",
      support:
        "In KRZ Technologies, quality is at the heart of everything we do. Our dedication to deliver top-tier solutions is unwavering, and we take pride in the meticulous attention to detail that defines our work.",
    },
    "Transparent Communication": {
      value: "Transparent Communication",
      support:
        "At KRZ Technologies, we believe that transparent communication is key to successful partnerships. We are committed to ensure that every interaction with our clients is clear, honest, and straightforward",
    },
  };

  const valuesPoints = {
    "Dependable and Transparent Service": {
      points: [
        <p className="valuepoint">
          Dependency signifies that clients can rely on the{" "}
          <span className="highlighted">
            service to be consistent, timely, and of high quality
          </span>
          . It reflects a promise to be there when needed, meeting expectations
          without fail.
        </p>,
        <p className="valuepoint">
          Transparency highlights the importance of{" "}
          <span className="highlighted">openness and honesty</span> in every
          aspect of the service.
        </p>,
        <p className="valuepoint">
          This means clear communication about{" "}
          <span className="highlighted">processes, pricing, and outcomes,</span>{" "}
          ensuring that clients are fully informed and there are no hidden
          surprises.
        </p>,
        <p className="valuepoint">
          Together, these{" "}
          <span className="highlighted">
            qualities build trust and foster long-term relationships
          </span>
          , as clients know they are working with a service provider that values
          integrity and reliability.
        </p>,
      ],
    },
    "Long-Term Support": {
      points: [
        <p className="valuepoint">
          <span className="highlighted">
            Proactive Maintenance and Updates:
          </span>{" "}
          We offer regular maintenance and updates to ensure your solutions
          remain current, secure, and effective as technology evolves.
        </p>,
        <p className="valuepoint">
          <span className="highlighted">Dedicated Supporting Teams:</span> Our
          experienced support teams are available to address any issues or
          questions, providing timely assistance and personalized solutions.
        </p>,
        <p className="valuepoint">
          <span className="highlighted">Ongoing Training and Resources:</span>{" "}
          We provide continuous training and access to resources, helping your
          team stay informed and proficient with the latest features and best
          practices.
        </p>,
        <p className="valuepoint">
          <span className="highlighted">Tailored Solutions:</span> We adapt our
          support services to meet your specific needs, offering scalable
          solutions that grow with your business.
        </p>,
        <p className="valuepoint">
          <span className="highlighted">Feedback-Driven Improvements:</span> We
          actively seek and incorporate client feedback to refine our services
          and enhance your overall experience.
        </p>,
        <p className="valuepoint">
          <span className="highlighted">Strategic Partnerships:</span> We build
          strong, long-term partnerships with our clients, ensuring that we
          understand your evolving needs and can support your objectives over
          time.
        </p>,
      ],
    },
    "Expertise and Professionalism": {
      points: [
        <p className="valuepoint">
          From the initial consultation to the final delivery, we ensure that
          every aspect of your project is handled with{" "}
          <span className="highlighted">meticulous care</span> and attention to
          detail. Our team understands the unique challenges and opportunities
          within your industry, allowing us to craft{" "}
          <span className="highlighted">
            solutions that are not only innovative but also perfectly aligned
            with your business goals.
          </span>
        </p>,
        <p className="valuepoint">
          We go beyond simply meeting expectations—we strive to exceed them.
          Whether it’s through our rigorous quality control processes, our{" "}
          <span className="highlighted">
            commitment to continuous improvement,
          </span>{" "}
          or our{" "}
          <span className="highlighted">
            proactive approach to problem-solving
          </span>
          , we deliver results that set new benchmarks in excellence.
        </p>,
        <p className="valuepoint">
          <span className="hightlighted">
            Our professionalism extends to every interaction
          </span>
          , ensuring clear communication, timely updates, and a collaborative
          partnership that makes you feel confident and supported throughout the
          process. With KRZ Technologies, you’re not just getting a service
          provider—you’re gaining a trusted partner who is as invested in your
          success as you are.
        </p>,
      ],
    },
    "Work Quality": {
      points: [
        <>
          <p className="highlightedTitle">Precision in Execution</p>
          <br />
          <p className="valuepoint">
            We approach every project with a commitment to precision, ensuring
            that each solution is tailored to meet your specific needs and
            industry standards. Our team’s rigorous processes ensure that
            nothing is left to chance, and every detail is perfected.
          </p>
        </>,
        <>
          <p className="highlightedTitle">Uncompromising Standards</p>
          <br />
          <p className="valuepoint">
            Quality is not just a goal; it's a standard we uphold in every
            project. We employ best practices, advanced technologies, and
            industry-leading methodologies to ensure that our deliverables
            consistently exceed expectations.
          </p>
        </>,
        <>
          <p className="highlightedTitle">Continuous Improvement</p>
          <br />
          <p className="valuepoint">
            As KRZ Technologies, we believe that there’s always room for
            improvement. We are dedicated in refining our processes, embracing
            innovation, and integrating feedback to elevate the quality of our
            work continuously.
          </p>
        </>,
        <>
          <p className="highlightedTitle">Client-Centric Quality Assurance</p>
          <br />
          <p className="valuepoint">
          Our quality assurance process is deeply client-focused. We work
            closely with you to understand your unique requirements and
            rigorously test our solutions to ensure that align perfectly with
            your objectives. Your satisfaction is the standard by which we
            measure our success.
          </p>
        </>,
        <>
          <p className="highlightedTitle">Reliable and Consistent Delivery</p>
          <br />
          <p className="valuepoint">
            We guarantee that our high standards of quality are maintained
            across every project. Our commitment to reliability means that you
            can trust us to deliver consistent, superior results, every time.
          </p>
        </>,
      ],
    },
    "Transparent Communication": {
      points: [
        <>
          <p className="highlightedTitle">Regular Updates and Reporting</p>
          <br />
          <p className="valuepoint">
            We keep you informed at every stage of your project’s progress. With
            regular updates, detailed reports, and milestone check-ins, you’ll
            always know where your project stands and what’s coming next.
          </p>
        </>,
        <>
          <p className="highlightedTitle">Collaborative Decision-Making</p>
          <br />
          <p className="valuepoint">
            We involve you in key decisions throughout the project, providing
            all the necessary information to help you make informed choices.
            Your input is vital to the success of the project, and we ensure you
            have the clarity needed to contribute effectively.
          </p>
        </>,
        <>
          <p className="highlightedTitle">Accessible and Responsive</p>
          <br />
          <p className="valuepoint">
            Our team is always accessible to you. Whether you have a question,
            need clarification, or want to discuss new ideas, we’re here to
            respond promptly and clearly, making sure you’re never left in the
            dark.
          </p>
        </>,
        <>
          <p className="highlightedTitle">Clear Documentation</p>
          <br />
          <p className="valuepoint">
            Every aspect of our work is documented with transparency in mind.
            From initial proposals and contracts to project plans and final
            deliverables, our documentation is clear, comprehensive, and easy to
            understand.
          </p>
        </>,
      ],
    },
  };

  useEffect(() => {
    // Scroll to the top of the page when this component is mounted
    window.scrollTo(0, 0);

    setCurrentNav("services");
  }, [setCurrentNav]);

  useEffect(() => {
    if (compValues === null) navigate("/");
  }, [compValues, navigate]);

  return (
    <>
      <div className="valuesContainer">
        <div className="valuesbannerContainer">
          {/* <img src='pics/valuesidedesign.svg' alt="side design" className='sideImageMount'/> */}
          <div className="valuesbannerContent">
            <p className="valuebannermainContent">
              {maincontent[compValues]?.value}
            </p>
            <p className="valuebannersupporttext">
              {maincontent[compValues]?.support}
            </p>
          </div>
        </div>
        <ul className="custom-list">
          {valuesPoints[compValues]?.points.map((val, index) => (
            <li key={index}>{val}</li>
          ))}
        </ul>
        {compValues === "Work Quality" && (
          <div className="workContainer">
            <img src='/pics/workbanner.png' alt='banner' style={{width:"100%",objectFit:"fill",height:"100vw"}}/>
            <p className="workContentContainer">
              “With KRZ Technologies, you can be confident that quality is not
              just an outcome—it’s the foundation upon which we build every
              solution. We are dedicated to support your business thrive through
              high-quality work that drives lasting success”
            </p>
          </div>
        )}
        <GetInTouch />

        <div className="valboxes">
          <div className="box1"></div>
          <div className="box2"></div>
          <div className="box3"></div>
        </div>
      </div>
    </>
  );
};

export default Values;
