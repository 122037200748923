import React, { useState } from "react";
import "../../Styles/RepeativeStyles/KRZToggleButton.css";
const KRZToggleButton = () => {
  const [isOn, setIsOn] = useState(false);

  const toggleSwitch = () => {
    setIsOn(!isOn);
  };
  return (
    <div
      className={`switch-container ${isOn ? "on" : "off"}`}
      onClick={toggleSwitch}
    >
      <div
        className={`toggle ${isOn ? "bounce-right on" : "bounce-left off"}`}
      ></div>
    </div>
  );
};

export default KRZToggleButton;
