import React, { useEffect, useRef, useState } from 'react'
import '../Styles/Contactus.css'
import { useAppContext } from '../Appcontext'
import KRZForm from './RepeativeComponent/KRZForm'

const Contactus = () => {
  const { setCurrentNav } = useAppContext()
  const [isVisible, setIsVisible] = useState(false)
  const contactUsRef = useRef(null)

  useEffect(() => {
    // Scroll to the top of the page when this component is mounted
    window.scrollTo(0, 0)
    setCurrentNav('contactus')
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true)
            observer.unobserve(entry.target) // Stop observing once visible
          }
        })
      },
      { threshold: 0.1 }
    )

    if (contactUsRef.current) {
      observer.observe(contactUsRef.current)
    }

    return () => {
      if (contactUsRef.current) observer.unobserve(contactUsRef.current)
    }
  }, [])

  return (
    <div
      ref={contactUsRef}
      className={`ContactContainer ${isVisible ? 'visible' : ''}`}
      data-testid="contact-us"
    >
      <div className="contactusContentContainer">
        <p className="contactusContentTitle">Contact</p>
        <p className="contactusContentHeading">Your Next Step Starts Here</p>
        <p className="contactusContentST">
          Connect with us to begin your journey toward achieving your business
          goals.
        </p>
      </div>
      <KRZForm text="Submit" className="contactusbtn" />
    </div>
  )
}

export default Contactus
