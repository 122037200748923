import React, {
  useEffect
} from "react";
import Services from "./Services";

import Challenges from "./Challenges";
import OurValues from "./OurValues";
import WhyKRZ from "./WhyKRZ";
import Contactus from "./Contactus";
import "../Styles/Banner.css";
import "../Styles/Banner1.css";
import NewBanner from "./NewBanner";


const Home = () => {
  
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])

  return (
    <div style={{ position: "relative" }}>
      <NewBanner/>
      <Services />
      <Challenges />
      <OurValues />
      <WhyKRZ />
      <Contactus />
    </div>
  );
};

export default Home;
