import React, { useEffect, useState } from "react";
import "../Styles/TopNavbar.css";
import { Link,useLocation } from "react-router-dom";
import { useAppContext } from "../Appcontext";
// import { useNavigate } from 'react-router-dom';
const TopNavbar = () => {
  const searchParams = useLocation();
  const path = searchParams.pathname.split("/");
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  // const navigate=useNavigate();
  const { serviceRef, scrollToService, currentNav, setCurrentNav } =
    useAppContext();

  const GoToService = () => {
    setTimeout(() => scrollToService(serviceRef), 50);
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY) {
      // User is scrolling down
      setShowNavbar(false);
    } else {
      // User is scrolling up
      setShowNavbar(true);
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);
    // console.log('awsdfghjuii',searchParams)

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <div
      className={`navcontainer flexHorizontalContainer ${
        showNavbar ? "visible" : "hidden"
      }`}
      style={{
        background:
          showNavbar && lastScrollY !== 0 ? "rgba(255,255,255)" : "transparent",
      }}
    >
      <Link to="/">
        <img src="KRZLogo1.svg" alt="Logo" className="logoTopNav" />
      </Link>
      <ul className="navsection flexHorizontalContainer">
        <Link
          to={"/"}
          className="navlist navSectionLink"
          onClick={() => GoToService()}
        >
          <span>{"Service"}</span>
          <div
            className={`navhighlight ${
              currentNav === "service" ? "navhighlight0" : ""
            }`}
          ></div>
        </Link>
        <Link
          to={"/aboutus"}
          className={`navlist navMidList navSectionLink`}
          onClick={() => setCurrentNav("aboutus")}
        >
          <span>About us</span>
          <div
            className={`navhighlight ${
              currentNav === "aboutus"  && path[1] === 'aboutus' ? "navhighlight1" : ""
            }`}
          ></div>
        </Link>
        <Link
          to={"/contactus"}
          className="navlist navSectionLink"
          onClick={() => setCurrentNav("contactus")}
          style={{ fontWeight: 800, color: "#FBB03B" }}
        >
          <span>Contact us</span>
          <div
            className={`navhighlight ${
              currentNav === "contactus" 
              && path[1] === 'contactus' ? "navhighlight2" : ""
            }`}
          ></div>
        </Link>
      </ul>
    </div>
  );
};

export default TopNavbar;

{
  /* <div className={`navhighlight ${currentNav==="aboutus"? 'navhighlight0':''}`}></div> */
}
