import React from "react";
import "../Styles/Careers.css";

const Careers = () => {
  return (
    
      <div className="careers-section">
        <div className="careers-text-container">
          <p className="careers-title">Careers</p>
          <p className="careers-content">
            Join our expanding team! Are you looking for your next opportunity?
            We want individuals who are genuinely passionate about digital
            technology and its potential.
          </p>
        </div>
        <img
          className="careers-icon"
          src="pics/career.svg"
          alt="Careers-Icon"
        />
      </div>
    
  );
};

export default Careers;
