import React, { useEffect, useRef, useState } from "react";
import AppContext from "./Appcontext";
import { useMediaQuery } from "react-responsive";

const AppProvider = ({ children }) => {
  const serviceRef = useRef(null);
  const [compValues, setCompValues] = useState(null);
  const [currentNav, setCurrentNav] = useState(null);
  const [footerNav, setFooterNav] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [open, setOpen] = React.useState(true);
  const [fixBanner, setFixBanner] = useState(true);
  const mobileView = useMediaQuery({ maxWidth: `440px` });
  const bigScreen = useMediaQuery({ minWidth: "1520px" });
  const BannerContents = [
    {
      mainText: (
        <p className="bannerMainText mainTextColor">
          Your Partner in <span className="spanTextColor">Digital</span>{" "}
          Transformation
        </p>
      ),
      supportText: (
        <p className="bannerSupportText mainTextcolor bannerSupportText1">
          Advanced <span className="spanTextColor">software development</span>{" "}
          services to elevate your business in the digital era.
        </p>
      ),
      imgsrc: "pics/banner1.svg",
    },
    {
      mainText: (
        <p className="bannerMainText mainTextColor">
          Your <span className="spanTextColor">Message</span>, Perfectly Crafted
        </p>
      ),
      supportText: (
        <p className="bannerSupportText mainTextcolor bannerSupportText2">
          {" "}
          Professional{" "}
          <span className="spanTextColor">Content writing services</span> that
          communicate your message with clarity and creativity.
        </p>
      ),
      imgsrc: "pics/banner2.svg",
    },
    {
      mainText: (
        <p className="bannerMainText mainTextColor ">
          <span className="spanTextColor">Designs</span> That Speak Your Brand's
          Language
        </p>
      ),
      supportText: (
        <p className="bannerSupportText mainTextcolor bannerSupportText3">
          Expert <span className="spanTextColor">design services</span> focused
          on delivering aesthetic and functional solutions.
        </p>
      ),
      imgsrc: "pics/banner3.svg",
    },
    {
      mainText: (
        <p className="bannerMainText mainTextColor" style={{ width: "130%" }}>
          Empowering Your Business with{" "}
          <span className="spanTextColor">Data Intelligence</span>
        </p>
      ),
      supportText: (
        <p className="bannerSupportText mainTextcolor bannerSupportText4">
          Unlock hidden opportunities with advanced{" "}
          <span className="spanTextColor">data analytics</span> solutions.
        </p>
      ),
      imgsrc: "pics/banner4.svg",
    },
  ];

  const scrollToService = (sectionRef) => {
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // setTimeout(()=>{
    //   sectionRef.current=null;
    //   setActiveIndex(3)
    // },500)
  };

  return (
    <AppContext.Provider
      value={{
        serviceRef,
        scrollToService,
        compValues,
        setCompValues,
        currentNav,
        setCurrentNav,
        footerNav,
        setFooterNav,
        BannerContents,
        activeIndex,
        setActiveIndex,
        open,
        setOpen,
        fixBanner,
        setFixBanner,
        mobileView,
        bigScreen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
