import React from "react";
import "../Styles/Footer.css";
import { Link, useLocation } from "react-router-dom";
import { useAppContext } from "../Appcontext";
import KRZToggleButton from "./RepeativeComponent/KRZToggleButton";
const Footer = () => {
  const searchParams = useLocation();
  const path = searchParams.pathname.split("/");
  const { setFooterNav, setOpen, mobileView, bigScreen } = useAppContext();
  const footerRouting = [
    { name: "Privacy policy", route: "policy" },
    { name: "Terms & Conditions", route: "terms" },
    { name: "Cookie Policy", route: "cookie" },
    { name: "Career Page", route: "career" },
  ];
  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="footerContainer">
      <div className="footerContentContainer">
        <Link to="/">
          <img src="KRZLogo1.svg" alt="logo" className="logoFooter" />
        </Link>
        <div className="content">
          {!mobileView && (
            <div className="locationContainer">
              <span className="location">
                {" "}
                <img
                  src="pics/location.svg"
                  alt="location"
                  className="locationimage"
                />
                Office Location{" "}
              </span>
              <p className="address">
                1/230/169, First Floor, CSS Towers, Pillayarkuppam, Perumugai,
                Vellore district, Tamil Nadu 632009.
              </p>
            </div>
          )}
          <ul className="footerRoutingContainer">
            {footerRouting.map((route, index) => (
              <li className="routeList" key={index}>
                <Link
                  to={
                    route.route !== "cookie" || mobileView
                      ? route.route
                      : path[1]
                  }
                  style={{ textDecoration: "none", color: "inherit" }}
                  onClick={() => {
                    handleScrollToTop(); // Scroll to top on click
                    route.route !== "cookie" || mobileView
                      ? setFooterNav(route.route)
                      : setOpen(true);
                  }}
                >
                  {route.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {mobileView && (
          <div className="locationContainer">
            <span className="location">
              {" "}
              <img
                src="pics/location.svg"
                alt="location"
                className="locationimage"
              />
              {/* Office Location */}
            </span>
            <p className="address">
              1/230/169, First Floor, CSS Towers, Pillayarkuppam, Perumugai,
              Vellore district, Tamil Nadu 632009.
            </p>
          </div>
        )}
      </div>

      {/* <KRZToggleButton/> */}
      <div className="copyright">Copyright Notice</div>
    </div>
  );
};

export default Footer;
