import React, { useEffect, useState, useRef } from "react";
import "../Styles/Challenges.css";
import { useMediaQuery } from "react-responsive";

const Challenges = () => {
  const mobileView = useMediaQuery({maxWidth : 440})
  const [currentList, setCurrentList] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const challengeRef = useRef(null);


  const challengeLists = [
    "Low Online Visibility",
    "Inconsistent Brand Identity",
    "Data-Driven Decisions",
    "Growth Scalability Issues",
  ];
  const contentMessage = [
    "KRZ Technologies enhances your brand's visibility by delivering engaging, SEO-optimized content. Through high-quality blog posts, articles, and social media, we attract and retain a larger audience, increasing brand awareness and strengthening your online presence.",
    "KRZ Technologies creates a cohesive, visually appealing brand identity. From logos to website design, we ensure all visual elements align with your brand, delivering a consistent and recognizable presence across platforms.",
    "KRZ Technologies delivers data analysis that transforms raw data into insights, helping businesses identify trends, optimize decisions, and improve strategies for increased profitability.",
    "KRZ Technologies boosts your brand's visibility with engaging, SEO-optimized content. High-quality blog posts, articles, and social media attract and retain a larger audience, enhancing brand awareness and online presence.",
  ];

  const renderChallengeList = () => {
    return (
     !mobileView ? <div className="messageContainer">
        <div className="indicatore"></div>
        <div className="messageContentContainer">
          <p className="contentTitle">{challengeLists[currentList]}</p>
          <p className="contentMesage">{contentMessage[currentList]}</p>
        </div> 
      </div>: <div className="messageContainer">
      <div className="messageContentContainer">
        <div className="indicatore"></div>
        
          <p className="contentTitle">{challengeLists[currentList]}</p>
          
        </div> 
        <p className="contentMesage">{contentMessage[currentList]}</p>
      </div>
    );
  };
  const handlePrevious = () => {
    setCurrentList((prev) => (prev > 0 ? prev - 1 : challengeLists.length - 1));
  };

  const handleNext = () => {
    setCurrentList((prev) => (prev < challengeLists.length - 1 ? prev + 1 : 0));
  };
  useEffect(() => {
    renderChallengeList();
  }, [currentList]);



  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target); // Stop observing once visible
          }
        });
      },
      { threshold: 0.1 }
    );

    if (challengeRef.current) {
      observer.observe(challengeRef.current);
    }

    return () => {
      if (challengeRef.current) observer.unobserve(challengeRef.current);
    };
  }, []);



  return (
    <div  ref={challengeRef} data-testid="challenges"
    className={`challengeContainer ${isVisible ? "visible" : ""}`}>
     {/* {!mobileView?( <><img src="pics/dealwchallengebanner.svg"alt="dealwithchallengesbanner" className="dwcbanner"/>
      <img src="pics/greaterthandwc.svg" alt="dealwithchallengesredirecticon" className="dwcredirecticon"/></>):("")} */}
      {/* <button><img src="pics/greaterthandwc.svg" alt="dealwithchallengesredirecticon" className="dwcredirecticon"/></button> */}
    {mobileView&&<img src="pics/dealwchallengebanner.svg"alt="dealwithchallengesbanner" className="dwcbanner"/>}
    {/* {mobileView&& <img src="pics/greaterthandwc.svg" alt="dealwithchallengesredirecticon" className="dwcredirecticon"/>} */}

    {mobileView && <div className="challengesButtonsContainer">
      {/* Next Button */}
    {currentList !== 0 &&<button 
        // className="navigationBtnRight" 
        onClick={handlePrevious}
        style={{ background: 'none', border: 'none', cursor: 'pointer' }} // Style for button
      >
        <img src="pics/lesserthan.svg" alt="previous" />
      </button>}
      
      {/* Previous Button */}
     {currentList !== challengeLists.length-1 && <button 
        // className="navigationBtnLeft" 
        onClick={handleNext}
        style={{ background: 'none', border: 'none', cursor: 'pointer' }} // Style for button
      >
        <img src="pics/greaterthan.svg" alt="next" />
      </button>}
    </div>}


      <p className="challengeTitle">Deal with Digital Challenges</p>
      <div className="challengeContentContainer">
        <ul className="challengeLists">
          {challengeLists.map((ele, index) => (
            <li
              key={index}
              className="challengeList"
              onMouseEnter={() => setCurrentList(index)}
              onMouseLeave={() => setCurrentList(index)}
            >
              <img
                src="pics/challengelist.png"
                alt="list image"
                className={`challengeListsimage ${
                  currentList === index ? "hovered" : ""
                }`}
              />
              <span
                className={`listContent ${
                  currentList === index ? "listhovered" : "defaultlistposition"
                }`}
              >
                {ele}
              </span>
            </li>
          ))}
        </ul>
        {renderChallengeList()}
        
      </div>
    </div>
  );
};

export default Challenges;
