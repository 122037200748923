import React, { useEffect, useState } from 'react'
import { useAppContext } from '../Appcontext'
import '../Styles/Service.css'
import { useMediaQuery } from 'react-responsive'

const Services = () => {
  const { serviceRef } = useAppContext()
  const [isVisible, setIsVisible] = useState(false)
  const [swipeDirection, setSwipeDirection] = useState('') // Track swipe direction
  const mobileView = useMediaQuery({ maxWidth: 440 })
  const [onActive, setOnActive] = useState(0)

  const [prevActive, setPrevActive] = useState(0)
  const [nextText, setNextText] = useState(0)

  const serviceInfo = {
    information_technologies: {
      points: ['Linux Administration', 'Web Development', 'DB Service'],
      message:
        'We specialize in delivering comprehensive IT services tailored to meet the dynamic needs of modern businesses. Our team of experts ensures your technology infrastructure is robust, secure, and optimized for performance.',
    },
    content_writing: {
      points: ['SEO Writing', 'Blog Writing', 'Technical Writing'],
      message:
        "KRZ Technologies offers comprehensive content writing services tailored to meet the needs of modern businesses. Whether it's blogs, web content, or articles, KRZ Technologies enhances your online presence and boosts search engine visibility.",
    },
    design: {
      points: [
        'Ideation',
        '3D Modeling',
        'Concept',
        'UX/UI Design',
        'Drafting',
        'Presentation Design',
        'Poster Design',
        'LOGO Design',
      ],
      message:
        'KRZ Technologies offers expert design services, including ideation, UX/UI, logo creation, poster design, drafting, and concept services. We deliver impactful, creative solutions to enhance your brand and user experience.',
    },
    data_analyst: {
      points: [
        'Machine Learning',
        'Data Engineering',
        'Predictive Analytics Solution',
        'Marketing Analytics Solution',
        'Predictive Analytics',
        'Descriptive Analytics',
        'Diagnostic Analytics',
      ],
      message:
        'KRZ Technologies provides expert data analytics services, including data visualization, predictive analytics, business intelligence, and reporting. We deliver actionable insights to enhance decision-making and drive business growth through data-driven strategies.',
    },
  }

  const changeActive = () => {
    switch (onActive) {
      case 0:
        return (
          <>
            <ul
              className={`serviceContentLists ${
                onActive === 0 && 'serviceContentAnimate1'
              }`}
            >
              {serviceInfo.information_technologies.points.map((val, index) => (
                <li className="serviceContentList serviceContentList0" key={index}>
                  <img
                    src="pics/servicepointer.svg"
                    alt="pointer"
                    className="pointericon"
                  />
                  <span className="servicePointerList">{val}</span>
                </li>
              ))}
            </ul>
            <div className="serviceMessageCont">
              <p
                className={`servicemessage ${
                  !mobileView ? onActive > prevActive 
                    ? 'servicemessageanimationup'
                    : 'servicemessageanimationdown' :""
                }`}
              >
                {serviceInfo.information_technologies.message}
              </p>
              <p
                style={{ marginTop: '6.0833vw' }}
                className={`servicemessage ${
                  !mobileView ?     onActive > prevActive 
                    ? 'servicemessageanimationup'
                    : 'servicemessageanimationdown' :''
                }`}
              >
                {serviceInfo.content_writing.message}
              </p>
            </div>
          </>
        )
      case 1:
        return (
          <>
            <ul
              className={`serviceContentLists ${
                onActive === 1 && 'serviceContentAnimate2'
              }`}
            >
              {serviceInfo.content_writing.points.map((val, index) => (
                <li className="serviceContentList serviceContentList1" key={index}>
                  <img
                    src="pics/servicepointer.svg"
                    alt="pointer"
                    className="pointericon"
                  />
                  <span className="servicePointerList">{val}</span>
                </li>
              ))}
            </ul>
            <div
              className="serviceMessageCont"
              style={{ position: 'relative' }}
            >
              {onActive > prevActive && (
                <p
                  style={{ position: 'absolute', top: mobileView?'-35vw':'-16vw' }}
                  className={`servicemessage ${
                    !mobileView ? onActive > prevActive 
                      ? 'servicemessageanimationup'
                      : 'servicemessageanimationdownn' :''
                  }`}
                >
                  {serviceInfo.information_technologies.message}
                </p>
              )}
              <p
                className={`servicemessage ${
                  !mobileView ? onActive > prevActive 
                    ? 'servicemessageanimationup'
                    : 'servicemessageanimationdownn' :''
                }`}
              >
                {serviceInfo.content_writing.message}
              </p>
              <p
                style={{ marginTop: '2.0833vw' }}
                className={`servicemessage ${
                  !mobileView ? onActive > prevActive 
                    ? 'servicemessageanimationup'
                    : 'servicemessageanimationdownn':''
                }`}
              >
                {serviceInfo.design.message}
              </p>
            </div>
          </>
        )
      case 2:
        return (
          <>
            <ul
              className={`serviceContentLists ${
                onActive === 2 && 'serviceContentAnimate1'
              }`}
            >
              {serviceInfo.design.points.map((val, index) => (
                <li className="serviceContentList" key={index}>
                  <img
                    src="pics/servicepointer.svg"
                    alt="pointer"
                    className="pointericon"
                  />
                  <span className="servicePointerList">{val}</span>
                </li>
              ))}
            </ul>
            <div
              className="serviceMessageCont"
              style={{ position: 'relative' }}
            >
              {onActive > prevActive && (
                <p
                  style={{ position: 'absolute', top:mobileView? '-42vw' :'-19vw', }}
                  className={`servicemessage ${
                    !mobileView ? onActive > prevActive 
                      ? 'servicemessageanimationupp'
                      : 'servicemessageanimationdown' :''
                  }`}
                >
                  {serviceInfo.content_writing.message}
                </p>
              )}
              <p 
                className={`servicemessage ${
                  !mobileView ?   onActive > prevActive 
                    ? 'servicemessageanimationupp'
                    : 'servicemessageanimationdown': ''
                }`}
              >
                {serviceInfo.design.message}
              </p>
              <p
                style={{ marginTop: '4.0833vw' }}
                className={`servicemessage ${
                  !mobileView ? onActive > prevActive 
                    ? 'servicemessageanimationupp'
                    : 'servicemessageanimationdown':''
                }`}
              >
                {serviceInfo.data_analyst.message}
              </p>
            </div>
          </>
        )
      case 3:
        return (
          <>
            <ul
              className={`serviceContentLists ${
                onActive === 3 && 'serviceContentAnimate2'
              }`}
            >
              {serviceInfo.data_analyst.points.map((val, index) => (
                <li className="serviceContentList" key={index}>
                  <img
                    src="pics/servicepointer.svg"
                    alt="pointer"
                    className="pointericon"
                  />
                  <span className="servicePointerList">{val}</span>
                </li>
              ))}
            </ul>
            <div
              className="serviceMessageCont"
              style={{ position: 'relative' }}
            >
              {onActive > prevActive && (
                <p
                  style={{ position: 'absolute', top: mobileView? '-36vw':'-16vw' }}
                  className={`servicemessage ${
                    !mobileView ?   onActive > prevActive
                      ? 'servicemessageanimationup'
                      : 'servicemessageanimationdown': ''
                  }`}
                >
                  {serviceInfo.design.message}
                </p>
              )}
              <p
                className={`servicemessage ${
                  !mobileView ? onActive > prevActive
                    ? 'servicemessageanimationup'
                    : 'servicemessageanimationdown' :''
                }`}
              >
                {serviceInfo.data_analyst.message}
              </p>
              <p
                style={{ marginTop: '2.0833vw' }}
                className={`servicemessage ${
                  !mobileView ?  onActive > prevActive
                    ? 'servicemessageanimationup'
                    : 'servicemessageanimationdown' :''
                }`}
              >
                {serviceInfo.design.message}
              </p>
            </div>
          </>
        )
      default:
        return null
    }
  }

  useEffect(() => {
    changeActive()
  }, [onActive])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true)
            observer.unobserve(entry.target) // Unobserve once visible
          }
        })
      },
      { threshold: 0.1 }
    )

    if (serviceRef.current) {
      observer.observe(serviceRef.current)
    }
    return () => {
      if (serviceRef.current) observer.unobserve(serviceRef.current)
    }
  }, [serviceRef])

  const handleServiceClick = (index) => {
    if (index !== onActive) {
      setSwipeDirection(index > onActive ? 'swipeRight' : 'swipeLeft')
      setOnActive(index)

      // Reset swipeDirection after animation
      setTimeout(() => setSwipeDirection(''), 500) // Adjust 500ms to match CSS animation duration
    }
  }

  return (
    <div
      id="services"
      ref={serviceRef}
      className={`serviceContainer ${isVisible ? 'visible' : ''}`}
      data-testid="services"
    >
      <p className="serviceTitle">Service</p>
      <ul className="serviceLists">
        <li
          className={`serviceList ${
            onActive === 0 ? 'serviceListChoosen' : ''
          }`}
          onClick={() => {
            handleServiceClick(0)
            // setOnActive(0)

            setPrevActive(onActive)
          }}
        >
          {mobileView ? 'IT Service' : 'Information Technology'}
          {!mobileView && (
            <div
              className={`highlight ${onActive === 0 ? 'highlight0' : ''}`}
            ></div>
          )}
        </li>
        <li
          className={`serviceList serviceListMid ${
            onActive === 1 ? 'serviceListChoosen' : ''
          }`}
          onClick={() => {
            handleServiceClick(1)
            setPrevActive(onActive)
          }}
        >
          Content Writing{' '}
          <div
            className={`highlight ${onActive === 1 ? 'highlight1' : ''}`}
          ></div>
        </li>
        <li
          className={`serviceList serviceListMid ${
            onActive === 2 ? 'serviceListChoosen' : ''
          }`}
          onClick={() => {
            handleServiceClick(2)
            setPrevActive(onActive)
          }}
        >
          Design{' '}
          <div
            className={`highlight ${onActive === 2 ? 'highlight2' : ''}`}
          ></div>
        </li>
        <li
          className={`serviceList serviceListMid ${
            onActive === 3 ? 'serviceListChoosen' : ''
          }`}
          onClick={() => {
            handleServiceClick(3)
            setPrevActive(onActive)
          }}
        >
          Data Analyst{' '}
          <div
            className={`highlight ${onActive === 3 ? 'highlight3' : ''}`}
          ></div>
        </li>
      </ul>
      <div
        className={`serviceContentContainer ${
          mobileView ? swipeDirection : ''
        }`}
      >
        {changeActive()}
      </div>
    </div>
  )
}

export default Services
