import React, { useEffect } from 'react'
import '../../Styles/Subpages/whyKRZ.css'
import GetInTouch from './GetInTouch'
import KRZButton from '../RepeativeComponent/KRZButton'
import { useAppContext } from '../../Appcontext'
import { useMediaQuery } from 'react-responsive'

const WhyKRZ = () => {
  const { setCurrentNav } = useAppContext()
  const mobileView = useMediaQuery({ maxWidth: 440 })
  const ICEGallery = [
    'pics/ICEG1.png',
    'pics/ICEG2.png',
    'pics/ICEG3.png',
    'pics/ICEG4.png',
    mobileView ? 'pics/ICEGM5.png' : 'pics/ICEG5.svg',
  ]
  const ICEGPoints = [
    <p className="ICEGCStyle ICEGCStyle0">
      <span className="ICEGCHLStyle ">Client-Centric Approach</span> Your
      Success is Our Success
    </p>,
    <p className="ICEGCStyle ICEGCStyle1">
      <span className="ICEGCHLStyle">Growth</span> Opportunities
    </p>,
    <p className="ICEGCStyle ICEGCStyle2">
      Building a <span className="ICEGCHLStyle">Unique Work Culture</span>
    </p>,
    <p className="ICEGCStyle ICEGCStyle3">
      Transforming{' '}
      <span className="ICEGCHLStyle">Challenges into Opportunities</span>
    </p>,
    <p className="ICEGCStyle ICEGCStyle4">
      <span className="ICEGCHLStyle">Flexibility</span> Adapting to Meet Your
      Needs
    </p>,
  ]
  const CoreStrengths = [
    <>
      <p className="pointsHeading">Industry Expertise</p>
      <p className="points">
        We bring relevancy and context in problem solving. Our domain experts
        leverage deep industry knowledge to identify and implement the most
        effective solutions to address your business challenges.
      </p>
    </>,
    <>
      <p className="pointsHeading">Product Mindset</p>
      <p className="points">
        Our teams dedicate time to thoroughly understand your product's business
        objectives, ensuring we deliver meaningful results rather than just
        outputs. This not only provides direction but a sense of ownership to
        our development teams.
      </p>
    </>,
    <>
      <p className="pointsHeading">Our Careers</p>
      <p className="points">
        We're constantly seeking passionate and talented individuals to join our
        expanding team. If you're eager to work on dynamic projects and
        collaborate with major brands, you've come to the right place.
      </p>
    </>,
  ]

  useEffect(() => {
    // Scroll to the top of the page when this component is mounted
    window.scrollTo(0, 0)
    setCurrentNav('services')
  }, [])

  return (
    <div className="whyKRZContainer">
      <div className="whyKRZbanner">
        <div className="subwhyKRZContentContainer">
          <p className="whyKRZBannerMainContent">lets talk about our work</p>
          <p className="whyKRZBannerSupportContent">
            At KRZ Technologies , we’re a growth-focused team driven by values
            that prioritize our customers and employees alike. Our commitment in
            creating a good work culture fosters an environment where innovation
            and transformation thrive.
          </p>
        </div>
        <div className="whyKRZimageContainer">
          <img
            src="pics/halfellipse.svg"
            alt="Side Render"
            className="whyKRZSideRendering"
          />
        </div>
      </div>
      <div className="ICEContainer">
        <p className="ICETitle">Innovation and Culture for Excellence</p>
        <div className="ICEGalleryContainer">
          {ICEGallery?.map((pic, index) => (
            <div className={`ICEG${index}`} key={index}>
              <img src={pic} alt="ICE" className="pics" />
              {ICEGPoints[index]}
            </div>
          ))}
        </div>
      </div>
      <div className="coreContainer">
        {!mobileView && (
          <img
            src="pics/coreBG.svg"
            alt="core side design"
            className="coreContainerSideBG"
          />
        )}
        <div className="coreContent">
          <p className="coreTitle">Our Core Strengths</p>
          {CoreStrengths.map((strength, index) => (
            <div
              className={`corePointsContainer ${
                index % 2 === 0
                  ? 'corePointaAlignment1'
                  : 'corePointaAlignment2'
              }`}
              key={index}
            >
              {strength}
            </div>
          ))}
          <KRZButton
            text={mobileView ? 'Join Now' : 'Join Our Team'}
            className="whyKRZButton joinNow" nav="career"
          />
        </div>
      </div>
      <GetInTouch />
    </div>
  )
}

export default WhyKRZ
