import React,{useEffect} from "react";
import Careers from "../Components/Careers";
import Jobvacancies from "../Components/Jobvacancies";
import Jobopening from "../Components/Jobopening";

const Occupation = () => {
  useEffect(()=>{
    window.scrollTo(0,0);
  },[])
  return (
    <div>
      <Careers />
      <Jobvacancies />
      <Jobopening />
    </div>
  );
};

export default Occupation;
