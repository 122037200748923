import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../Appcontext";
import "../../Styles/FooterPagesStyle/DynamicTermsAndPrivacy.css";
import KRZToggleButton from "../RepeativeComponent/KRZToggleButton";

const DynamicTermsAndPrivacy = () => {
  const { footerNav, setFooterNav, mobileView, bigScreen } = useAppContext();
  const navigate = useNavigate();
  const searchParams = useLocation();
  const path = searchParams.pathname.split("/");

  const handleNavigate = () => {
    setFooterNav("policy");
    navigate("/policy");
  };

  useEffect(() => {
    setFooterNav(path[1]);
    window.scrollTo(0,0)
  }, [path]);

  const termsAndCondition = [
    {
      heading: "Introduction",
      content: (
        <p style={{ margin: 0 }}>
          Thank you for visiting the KRZ Technologies website (the “Website”).
          <p style={{ margin: 0 }}>
            These terms and conditions (the “Terms”) form a legally binding
            agreement between you and KRZ Technologies (“KRZ”, “we”, “us”,
            “our”) and govern your use of the Website and all texts,
            information, software, graphics, and other materials (collectively,
            “Content”) that we may make available through the Website. Please
            review these Terms carefully before using the Website. By accessing
            or using the Website, you agree to comply with them. If you do not
            agree with the Terms, kindly refrain from using the Website.
          </p>
        </p>
      ),
      type: "para",
    },
    {
      heading: "Changes",
      content:
        "We may modify, update, or remove provisions of these Terms at any time. Any changes will take effect from the date they are posted on the Website. Your continued use of the Website after modifications indicates your acceptance of the new Terms.",
      type: "para",
    },
    {
      heading: "Availability of the Website",
      content:
        "We will make reasonable efforts to ensure the Website is available without interruption. However, the Website may occasionally be unavailable due to maintenance or reasons beyond our control. You acknowledge that there may be errors or bugs in the Website.",
      type: "para",
    },
    {
      heading: "Your Interaction with the Website",
      content: [
        "Refrain from using the Website or any Content for illegal purposes.",
        "Avoid submitting any inaccurate, harmful, or inappropriate information through our contact forms.",
        "Do not use any software or devices that could disrupt the Website's operation.",
        "Do not modify or delete any Content without proper authorization.",
        "Refrain from attempting unauthorized access to our systems or networks.",
        "Do not submit materials that infringe on the rights of third parties unless you have a valid legal basis.",
        "Avoid publishing false information that could damage our reputation.",
        "Do not disclose personal information that reveals sensitive details.",
        "Refrain from posting advertisements or solicitations without our explicit written consent.",
      ],
      type: "points",
    },
    {
      heading: "Intellectual Property Rights",
      content: (
        <p style={{ margin: 0 }}>
          <p style={{ margin: 0 }}>
            All Content, trademarks, logos, and other materials on the Website
            are protected by copyright and intellectual property laws, owned by
            KRZ or its licensors. All rights are reserved.
          </p>
          <p style={{ margin: 0 }}>
            You may use the Website for informational purposes and to reach out
            to us, but you cannot use copyrighted materials or trademarks
            without our prior written permission. Any information you provide
            through the Website becomes our property and may be used at our
            discretion.
          </p>
        </p>
      ),
      type: "para",
    },
    {
      heading: "Information Collection",
      content: (
        <p style={{ margin: 0 }}>
          The processing of your personal data is governed by our{" "}
          <span
            // to="policy"
            style={{
              color: "inherit",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => handleNavigate()}
          >
            Privacy Policy.
          </span>
        </p>
      ),
      type: "para",
    },
    {
      heading: "Disclaimer",
      content: `The Website, Content, and any downloadable materials are provided on an "as is" basis, without any warranties. We do not guarantee the security, completeness, or uninterrupted functionality of the Website and are not responsible for any damages that may arise from its use.`,
      type: "para",
    },
    {
      heading: "Limitation of Liability",
      content:
        "To the fullest extent permitted by law, KRZ and its affiliates are not liable for any damages arising from your use of the Website, including direct, indirect, incidental, or consequential damages.",
      type: "para",
    },
    {
      heading: "Indemnification",
      content:
        "You agree to indemnify and hold harmless KRZ, its affiliates, and representatives from any claims or damages arising from your use of the Website or violation of these Terms.",
      type: "para",
    },
    {
      heading: "Waiver and Severability",
      content:
        "Our failure to enforce any right under these Terms does not constitute a waiver of that right. If any provision is found invalid, the remaining provisions shall remain in effect.",
      type: "para",
    },
    {
      heading: "Entire Agreement",
      content:
        "These Terms constitute the entire agreement between you and KRZ regarding your use of the Website.",
      type: "para",
    },
  ];

  const policies = [
    {
      heading: "Introduction",
      content: (
        <p style={{ margin: "0" }}>
          <p style={{ margin: "0 0 2.5984vw 0" }}>
            Please read this Cookie Policy (“Policy”) carefully, as it explains
            how KRZ Technologies, located at 1/230/169, , First floor, CSS
            Towers, Pillayarkuppam, Perumugai, Vellore district ,632009 and its
            affiliated companies (“we,” “us,” or “our”), along with our
            partners, use cookies and how you can manage them. This Policy
            should be read alongside our Privacy Policy, which details how we
            collect and use the personal data you provide through your use of
            this website and the products, services, and features we offer as
            part of our website
          </p>{" "}
          <p style={{ margin: "0 0 3.3984vw 0" }}>
            Protecting your data and using it in ways you expect is our top
            priority at KRZ Technologies. This Privacy Notice is designed to
            inform you about how we process your personal data and your rights
            regarding that processing in accordance with applicable data
            protection laws.
            <p style={{ margin: 0 }}>
              As the controller of your personal data, we are responsible for
              determining how and why your personal data is used, and we oversee
              the data processing outlined in this Notice.
            </p>
          </p>
          <p>
            By using this website, you accept this Privacy Notice and consent to
            the processing of your personal data as described. This Privacy
            Notice will also apply in other instances where we specifically
            reference it, such as: a) when we receive your personal data from
            third parties and are required to inform you about its use; b) when
            we first contact you via email or other means; c) when we process
            your personal data during our events or those of our partners, with
            a specific reference to this Privacy Notice.
          </p>
        </p>
      ),
      type: "para",
    },
    {
      heading: "Personal Data We Collect",
      content: (
        <p style={{ margin: "0" }}>
          <p style={{ margin: "0 0 2.5984vw 0" }}>
            If you are applying for a position at KRZ Technologies, we collect
            and process the following personal data, either directly from you or
            from public sources (such as LinkedIn): your name, phone number,
            email address, location, information from your CV, LinkedIn profile
            details, and records of our communications with you. This
            information is gathered for our recruitment efforts to attract,
            assess, and onboard potential team members. This processing helps us
            match candidates with appropriate roles and ensures a fair and
            efficient hiring process. It is driven by our legitimate business
            interest in hiring top talent or based on your consent when you
            reach out to us through our website or during our events. We are
            committed to ensuring that all recruitment-related data processing
            adheres to the principles of fairness, transparency, accuracy, and
            confidentiality, providing candidates with clarity and control over
            their data at all times.
          </p>{" "}
          <p style={{ margin: "0 0 3.3984vw 0" }}>
            If you are a contact person at a company that is an existing or
            potential client of KRZ Technologies, we collect and process the
            following personal data, obtained either directly from you or from
            public sources (such as LinkedIn): your name, phone number, email
            address, location, LinkedIn profile details, your role/position,
            employment history, and records of our communications with you. This
            information is gathered to support proactive client acquisition
            efforts and to cultivate strong, collaborative relationships with
            our clients. This includes facilitating communication on projects,
            organizing business trips and visits, and addressing matters
            essential to our successful cooperation. This processing is
            necessary for KRZ's business operations, development, and growth,
            ensuring the efficiency and effectiveness of our client engagement
            processes. In this regard, we rely on our legitimate interests when
            reaching out to potential clients or securing new projects from
            existing clients, as well as on the legal basis of contract
            conclusion and execution for data used during our communications
            throughout the contract process.
          </p>{" "}
          <p>
            If you are a contact person at a company that is an existing or
            potential vendor or supplier for KRZ Technologies, we collect and
            process the following personal data, obtained either directly from
            you or from public sources (such as LinkedIn or your website): your
            name, phone number, email address, LinkedIn profile details, your
            role/position, and records of our communications with you. This
            information is gathered primarily to procure goods and services that
            support our company’s operations. This processing is necessary for
            fulfilling contracts, ensuring smooth business operations, and is
            supported by our legitimate business interests in efficient resource
            allocation.
          </p>
        </p>
      ),
      type: "para",
    },

    {
      heading: "Purpose of Collecting your Personal Data",
      content: [
        "Contacting you to inform you about products and services we offer, as well as those offered by our partners, and to inquire about the products and services your company provides or any open positions that may interest you.",
        "Communicating with you, including responding to inquiries you may have made.",
        "Enhancing the user experience on our website.",
        "Improving our website’s functionality.",
        "Enhancing customer service.",
        "Managing our contractual relationships.",
        "Complying with our legal obligations, performing tasks in the public interest, or protecting the vital interests of our users and others.",
      ],
      type: "points",
    },
    {
      heading: "Lawful Basis for Processing",
      content: `We process your personal data under the following lawful basis and for the following reason`,
      type: "para",
    },
    {
      heading: "Content",
      content: `At KRZ Technologies, we rely on your consent to use your personal data for optimizing and enhancing our website, developing new features and services (including when data is collected as part of aggregated data), and delivering relevant advertising materials, in accordance with your device or browser’s privacy settings. This legal basis also applies when we respond to your inquiries, such as questions about open positions or collaboration opportunities, and during your attendance at our events.You have the right to withdraw your consent for the processing of your personal data at any time. Please keep in mind that withdrawing your consent does not render any prior processing unlawful. Additionally, if you decide to withdraw your consent, it may affect our ability to respond to you or provide our services and job opportunities.`,
      type: "para",
    },
    {
      heading: "Compliance with our legal obligations",
      content:
        "At KRZ Technologies, we rely on your consent to use your personal data for optimizing and enhancing our website, developing new features and services (including when data is collected as part of aggregated data), and delivering relevant advertising materials, in accordance with your device or browser’s privacy settings. This legal basis also applies when we respond to your inquiries, such as questions about open positions or collaboration opportunities, and during your attendance at our events.You have the right to withdraw your consent for the processing of your personal data at any time. Please keep in mind that withdrawing your consent does not render any prior processing unlawful. Additionally, if you decide to withdraw your consent, it may affect our ability to respond to you or provide our services and job opportunities.",
      type: "para",
    },
    {
      heading:
        "To fulfill a contract with you or to take actions at your request prior to entering into a contract.",
      content:
        "We rely on this legal basis to offer certain services to you, receive specific services from you, or engage in employment or other contractual relationships with you or the company you represent",
      type: "para",
    },
    {
      heading: "Safety of Your Personal Data",
      content:
        "At KRZ Technologies, we implement appropriate organizational and security measures for data collection, storage, and processing to safeguard against unauthorized access, alteration, disclosure, or destruction of your personal information, including your username, password, transaction details, and other data stored on our website. We ensure that any personal data we process will not be retained longer than necessary for its intended purpose, including compliance with legal obligations. If you withdraw your consent, we may retain certain personal information for the duration required by applicable laws to resolve disputes and enforce our rights and agreements.",
      type: "para",
    },
    {
      heading: "Children’s privacy",
      content:
        "KRZ Technologies’ services and products are not intended for individuals under the age of 18. We do not knowingly collect or request personal information from children under this age. If we become aware that we have received personal data from a minor, we will promptly delete it from our records. If you are a parent or guardian and believe we have collected such data, please reach out to us using the information provided in the “Contact Information” section below, and we will remove it as soon as possible, in accordance with applicable Indian laws.",
      type: "para",
    },
    {
      heading: "Information tracking",
      content:
        "This website may automatically gather information about you in various ways, including tracking your activities through your IP address, computer settings, recently visited URLs, operating system, date and time, preferences, and saved options based on your browser settings. We use your IP address to help diagnose server issues and manage the website effectively.",
      type: "para",
    },
    {
      heading: "Changes to the Privacy Notice",
      content: (
        <p style={{ margin: 0 }}>
          <p style={{ margin: 0 }}>
            We may update this Privacy Notice by publishing a new version on our
            website at our discretion. Any changes will be clearly posted so you
            can always understand what information we collect, how we use it,
            and under what circumstances we may disclose it.
          </p>
          <p style={{ margin: 0 }}>
            The date of the last modification to this Privacy Notice is noted at
            the beginning of this document.
          </p>
          <p style={{ margin: 0 }}>
            We encourage you to review the most recent version of this Privacy
            Notice regularly, especially each time you visit our website.
            Additionally, we will make reasonable efforts to notify you of
            significant changes via email (if we have your address) or through
            other means.
          </p>
        </p>
      ),
      type: "para",
    },
    {
      heading: "Cookie Policy",
      content: (
        <p style={{ margin: 0 }}>
          <p style={{ margin: 0 }}>
            Like most websites, KRZ Technologies uses cookies to enhance your
            browsing experience.
          </p>
          <p style={{ margin: 0 }}>
            Cookies are small data files stored on your computer that allow us
            to recognize your device during your next visit. They automatically
            collect aggregate non-personal information, such as browser types,
            operating systems, domain names, website visits, average visit
            duration, and pages viewed per visit.
          </p>
          <p style={{ margin: 0 }}>
            There are two main types of cookies: session cookies and persistent
            cookies. Session cookies are removed from your device once you close
            your browser, while persistent cookies remain until you delete them
            or they reach their expiration date.
          </p>
          <p style={{ margin: 0 }}>
            For more details, please refer to our Cookie Policy.
          </p>
        </p>
      ),
      type: "para",
    },
    {
      heading: "Your Privacy Rights",
      content: (
        <p style={{ margin: 0 }}>
          <p style={{ margin: 0 }}>
            Your key privacy rights include the following: the right to be
            informed about how your personal data is collected and used, the
            right to access your personal data, the right to rectification, the
            right to erasure, the right to restrict processing, the right to
            object to processing, the right to data portability, the right to
            lodge a complaint with a supervisory authority, and the right to
            withdraw consent.
          </p>
          <p style={{ margin: 0 }}>
            To exercise any of these rights, please reach out to us using the
            contact information provided below. When submitting a request,
            please specify the details, including the nature of the request, the
            right you wish to exercise, the personal data involved, and any
            other relevant information to help us process your request
            efficiently. In cases of doubt, we may ask you to verify your
            identity.
          </p>
          <p style={{ margin: 0 }}>
            We will strive to respond to your requests as promptly as possible,
            keeping in mind the complexity of the request. However, if we are
            handling a significant number of requests, our response time may be
            longer than anticipated.
          </p>
        </p>
      ),
      type: "para",
    },
    {
      heading: "Contact information",
      content: (
        <p style={{ margin: 0 }}>
          <p style={{ margin: 0 }}>Contact Information</p>
          <p style={{ margin: 0 }}>
            If you have any questions regarding this Privacy Policy, please
            contact us directly:
          </p>
          <p style={{ margin: 0 }}>Name: KRZ technologies</p>
          <p style={{ margin: 0 }}>
            Postal address: 1/230/169, , First floor, CSS Towers,
            Pillayarkuppam, Perumugai, Vellore district ,632009
          </p>
        </p>
      ),
      type: "para",
    },
  ];

  const cookies = [
    { subDivision: "Cookie Policy" },
    {
      heading: "Introduction",
      content: (
        <p style={{ margin: "0 0 2.5984vw 0" }}>
          KRZ Technologies is dedicated to safeguarding your personal data and
          upholding your privacy rights. This "Cookies Policy" is designed to
          inform you about how we at KRZ Technologies utilize cookies and
          similar technologies on our websites. For additional details on how we
          manage your personal data, please refer to our Privacy Statement.
        </p>
      ),
      type: "para",
    },
    {
      heading: "What are cookies?",
      content: (
        <p style={{ margin: "0" }}>
          <p style={{ margin: "0 0 2.5984vw 0" }}>
            Cookies are small text files containing data that are stored by your
            browser on your device (such as your computer, tablet, or mobile
            phone) or by the webpage you are viewing when you visit our website,
            unless you've modified your browser settings to refuse cookies.{" "}
          </p>
          <p style={{ margin: "0 0 2.5984vw 0" }}>
            {" "}
            Cookies allow a website to remember certain information, such as
            your actions and preferences, over time. This means you won’t have
            to re-enter your information each time you return to the site or
            navigate between pages.{" "}
          </p>
          <p style={{ margin: "0 0 2.5984vw 0" }}>
            <p style={{ margin: 0 }}>
              You can manage your cookie preferences through the KRZ
              Technologies Cookies Preference Center.{" "}
            </p>
            <p>
              Depending on your settings in the Cookies Preference Center on
              your mobile device, the following information may be collected via
              cookies or similar technologies: your unique device identifier,
              mobile device IP address, details about your device's operating
              system, mobile carrier, and your country location (to the extent
              allowed by applicable law).{" "}
            </p>
          </p>
          <p style={{ margin: "0 0 2.5984vw 0" }}>
            For more general information about cookies and how they function,
            you can visit All About Cookies.
          </p>
        </p>
      ),
      type: "para",
    },
    { subDivision: "Cookie Preference Settings" },
    {
      subhead: (
        <div className="cookiesubhead">
          <p className="heading1">
            What type of cookies are used by KRZ website and why?
          </p>
          <KRZToggleButton />
        </div>
      ),
      content: (
        <p style={{ margin: "0 0 2.5984vw 0" }} className="subcontent">
          Our website uses various types of cookies to differentiate you from
          other users. While you can visit most of the site without enabling
          cookies, certain functionalities may require them to work properly.
          Allowing cookies can also enhance your browsing experience by making
          it more personalized. Cookies that are set and managed by KRZ
          Technologies are known as "first-party cookies," while those from
          external sources are referred to as "third-party cookies," as detailed
          below. As outlined in our cookies banner and Cookies Preference
          Center, you can find information about the cookies used on KRZ
          Technologies.
        </p>
      ),
      type: "para",
    },
    {
      subhead: (
        <div className="cookiesubhead">
          <p className="heading1">Strictly Necessary Cookies</p>
          <KRZToggleButton />
        </div>
      ),
      content: (
        <p style={{ margin: "0 0 2.5984vw 0" }} className="subcontent">
          These cookies are crucial for delivering the services you request on
          our website and for meeting our legal obligations, including security
          requirements under applicable privacy and data protection laws. We do
          not require your consent to use these cookies, as they are necessary
          for the website to function properly and provide the requested
          services. These cookies remain active on the site and do not store any
          personal information.
        </p>
      ),
      type: "para",
    },
    {
      subhead: (
        <div className="cookiesubhead">
          <p className="heading1">Performance Cookies</p>
          <KRZToggleButton />
        </div>
      ),
      content: (
        <p style={{ margin: "0 0 2.5984vw 0" }} className="subcontent">
          We utilize both first-party and third-party performance cookies on our
          website. These cookies gather information about how you interact with
          our site, such as the pages you visit and any errors that may occur.
          They help us enhance the website's performance, understand user
          interests, and assess the effectiveness of our content, enabling us to
          provide more relevant material to you. Importantly, these cookies do
          not collect information that identifies you as an individual. Any data
          shared with external service providers is limited to instances where
          they act on our behalf, and they are not permitted to use the data for
          their own purposes.
        </p>
      ),
      type: "para",
    },
    {
      subhead: (
        <div className="cookiesubhead">
          <p className="heading1">Functional Cookies</p>
          <KRZToggleButton />
        </div>
      ),
      content: (
        <p style={{ margin: "0 0 2.5984vw 0" }} className="subcontent">
          These cookies enable our website to remember your previous choices.
          For instance, they can retain your login details or optimize settings
          like video streaming speed and volume. We may use performance cookies
          to remember your operational preferences, ensuring you don’t have to
          reset them each time you visit our site.
        </p>
      ),
      type: "para",
    },
    {
      subhead: (
        <div className="cookiesubhead">
          <p className="heading1">Advertising Cookies</p>
          <KRZToggleButton />
        </div>
      ),
      content: (
        <p style={{ margin: "0 0 2.5984vw 0" }} className="subcontent">
          We use third-party cookies, which are created by domains other than
          KRZ Technologies, to gather information on user behavior,
          demographics, advertising, and personalized marketing. This may
          include displaying ads on websites not affiliated with KRZ
          Technologies. You can choose to opt in or opt out of these cookies by
          adjusting your preferences in our Cookies Preference Center.
        </p>
      ),
      type: "para",
    },
    {
      subhead: (
        <div className="cookiesubhead">
          <p className="heading1">Social Media Cookies</p>
          <KRZToggleButton />
        </div>
      ),
      content: (
        <p style={{ margin: "0 0 2.5984vw 0" }} className="subcontent">
          These cookies are placed by various social media services integrated
          into our website, allowing you to share our content with your
          networks. They can track your browsing activity across different sites
          and create a profile based on your interests. We do not have control
          over these social media cookies, and they do not grant us access to
          your social media accounts. The respective social media platform may
          also display ads and content from us when you visit their sites or use
          their applications. Additionally, if you use a social media sharing
          button or widget on our site, the social network that provided it will
          log your action for its own purposes. For details about their cookies,
          please refer to the privacy policies of the relevant social media
          platforms.
        </p>
      ),
      type: "para",
    },
    {
      subhead: (
        <div className="cookiesubhead">
          <p className="heading1">How do I turn off cookies?</p>
        </div>
      ),
      content: (
        <p style={{ margin: "0 0 2.5984vw 0" }} className="subcontent">
          You can choose to consent to our use of cookies before exploring our
          websites. Your cookie preferences can be managed through our Cookies
          Preference Center.
        </p>
      ),
      type: "para",
    },
  ];

  return (
    <div className="dynamictpContainer">
      {
        <p className="dynamicTPTitle">
          {footerNav === "terms"
            ? "Terms and conditions"
            : footerNav === "policy"
            ? "Privacy Notice"
            : footerNav === "cookies"
            ? "Cookie Policy"
            : ""}
        </p>
      }

      {footerNav === "terms" &&
        termsAndCondition.map((term, index) => (
          <div key={index} className="contentContainer">
            <p className="tpHeading">{term.heading}</p>
            {term.type === "para" && (
              <p className="tpContent">{term.content}</p>
            )}
            {term.type === "points" && (
              <ul className="dplistcontainer">
                {term.content.map((point, index2) => (
                  <li key={index2} className="tpContent">
                    {point}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}

      {footerNav === "policy" &&
        policies.map((pol, index) => (
          <div key={index} className="contentContainer">
            <p className="tpHeading">{pol?.heading}</p>
            {pol.type === "para" && <p className="tpContent">{pol.content}</p>}
            {pol.type === "points" && (
              <ul className="dplistcontainer">
                {pol.content.map((point, index2) => (
                  <li key={index2} className="tpContent">
                    {point}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}

      {footerNav === "cookie" &&
        cookies.map((pol, index) => (
          <div key={index} className="contentContainer">
            {pol?.heading && <p className="tpHeading">{pol?.heading}</p>}
            {pol?.subDivision && (
              <p className="dynamicTPTitle">{pol?.subDivision}</p>
            )}
            {pol?.subhead && pol?.subhead}
            {pol.type === "para" && <p className="tpContent">{pol.content}</p>}
            {pol.type === "points" && (
              <ul className="dplistcontainer">
                {pol.content.map((point, index2) => (
                  <li key={index2} className="tpContent">
                    {point}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
    </div>
  );
};

export default DynamicTermsAndPrivacy;
