import React, { useEffect, useState } from 'react'
import '../Styles/NewBanner.css'
import { useMediaQuery } from 'react-responsive'

const NewBanner = () => {
  const bannerContent = [
    'Software solutions',
    'Design',
    'Content Writings',
    'Data Analyst',
  ]
  let dur = 0
  const [autotext, setAutoText] = useState(bannerContent[0])
  const [nextText, setNextText] = useState(bannerContent[1])
  const mobileView = useMediaQuery({ maxWidth: 440 });
  const [animationDuration,setAnimationDuration]=useState(8.510)
  const [textDuration1,setTextDuration1]=useState(0.55)
  let i = 0
  const [animate, setAnimate] = useState(false);
  const [counterr,setCounterr]=useState(0);
 
  useEffect(() => {
    document.documentElement.style.setProperty('--animation-duration',`${animationDuration}s`)
    document.documentElement.style.setProperty('--text-change-duration',`${0.55}s`)
    const totalDuration = 8290 // 8.6 seconds in milliseconds
    let changeInterval = totalDuration / bannerContent.length // Time between text changes
  
    
    const changeText = () => {
      setAnimate(true) // Start animation
      setCounterr(counterr+1);
      // Schedule text change
      const timeout = setTimeout(() => {
        i = (i + 1) % bannerContent.length // Cycle through texts
        setAutoText(bannerContent[i])
        setNextText(bannerContent[(i + 1) % bannerContent.length])
        setAnimate(false) // End animation
      }, 490) // Match this with the duration of the scroll animation (490 ms)
      return () => clearTimeout(timeout)
    }
    dur+=0.10;
    if(counterr> 2){
      setAnimationDuration(animationDuration-0.420);
      setCounterr(0);
      setTextDuration1(textDuration1+0.20)
    }
    const interval = setInterval(() => {changeText(); document.documentElement.style.setProperty('--animation-duration',`${animationDuration}s`);
    document.documentElement.style.setProperty('--text-change-duration',`${textDuration1}s`)
  }, changeInterval)

    return () => clearInterval(interval)
  }, [animationDuration,textDuration1])
  return (
    <div className="newBannerContainer" data-testid="new-banner">
      <div className="newBannerCenContContainer">
        <img
          src="pics/NewBannerCC.svg"
          alt="banner design"
          className="cenContImage"
        />
        <div className="cenConTextCont">
          <div className="cenConTextCont_MainCont">
            <p className="newBannerccMainfont">
              We provide comprehensive support in
            </p>{' '}
            <div className={`newBannerHLContainer }`}>
              <span
                className={`newBannerccMainHighlight ${
                  animate ? 'scroll-up' : ''
                }`}
              >
                {autotext}
              </span>{' '}
              <span
                className={`newBannerccMainHighlight ${
                  animate ? 'scroll-up' : 'hidden'
                }`}
              >
                {nextText}
              </span>
            </div>
          </div>
          <p className="cenConSubText">
            Empowering businesses with{' '}
            <span style={{ color: '#fff', fontWeight: 600 }}>
              innovative solutions
            </span>{' '}
            tailored to achieve success.
          </p>
        </div>
      </div>
      {/* from top clock wise */}
      <div className="newBannerDesign1"></div>
      <div className="newBannerDesign2"></div>
      {!mobileView && <div className="newBannerDesign3"></div>}
      {!mobileView && <div className="newBannerDesign4"></div>}
      <div className="newBannerDesign5"></div>
      <div className="newBannerDesign6"></div>
      <div className="newBannerDesign7"></div>
    </div>
  )
}

export default NewBanner
